import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { jaJP } from '@mui/x-date-pickers/locales';


const JoinDatePick = (props) => {
    
    const theme = createTheme(
        {},
        jaJP, // x-date-pickers translations
    );

    return (
        <>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: "YYYY年MM月", monthShort: "MM月" }}>
                    <DatePicker
                        fullWidth
                        label="入社日"
                        defaultValue={dayjs()} 
                        showDaysOutsideCurrentMonth
                        format="YYYY-MM-DD"
                        value={props.joinDate ? dayjs(props.joinDate) : dayjs()}
                        onChange={(date) => props.setJoinDate(date.format('YYYY-MM-DD'))}
                        sx={{
                            width: '100%',
                            marginTop: '16px',
                            marginBottom: '8px',
                        }}
                    />
                </LocalizationProvider>
            </ThemeProvider>
        </>
    );
}

export default JoinDatePick;
import React, {useEffect, useState} from 'react';
import '../dashboard/css/css.scss';
import TimePicker from './Timepicker';
import AxiosConfig from '../../AxiosConfig';

function formatTime(time) {
  if (time) {
      return time.slice(0, 5);
  }
  return '';
}

function getTrueFields(works) {
  const trueFields = [];
  if(works) {
      if (works.is_late) trueFields.push('遅刻');
      if (works.is_early) trueFields.push('早退');
      if (works.is_morning_rest) trueFields.push('午前休');
      if (works.is_afternoon_rest) trueFields.push('午後休');
  }

  return trueFields;
}

function calculateNightWorkHours(startTime, endTime, restTime) {
    
    if(!startTime || !endTime || !restTime) {
        return false;
    } 

    let [startHours, startMinutes, startSeconds] = startTime.split(":").map(String);
    let [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
    let [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
    
    let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
    let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
    let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

    let nightTimeStart1 = start;
    nightTimeStart1.setHours(startHours, startMinutes, startSeconds);
    
    let nightTimeEnd2 = end;
    nightTimeEnd2.setHours(endHours, endMinutes, endSeconds);

    let nightTimeRest3 = rest;
    nightTimeRest3.setHours(restHours, restMinutes, restSeconds);

    if(Number(endHours) === 0) {
        endHours = 24;
    }
    if(Number(endHours) === 1) {
        endHours = 25;
    }
    if(Number(endHours) === 2) {
        endHours = 26;
    }
    if(Number(endHours) === 3) {
        endHours = 27;
    }
    if(Number(endHours) === 4) {
        endHours = 28;
    }
    if(Number(endHours) === 5) {
        endHours = 29;
    }
    if(Number(endHours) === 6) {
        endHours = 30;
    }

    let nightWorkTime = Number(endHours) - Number(startHours) - Number(restHours);
    if(nightWorkTime < 0) {
        nightWorkTime = 24 + Number(endHours) - Number(startHours) - Number(restHours);
    }
    let nightWorkTimeMin; 
    // nightWorkTimeMin = Number(endMinutes) - Number(startMinutes);
    if(Number(endHours) === 22 || Number(endHours) === 23 ) {
        nightWorkTimeMin = Number(endMinutes) - Number(restMinutes);
    } else if(Number(endHours) >= 24 || Number(endHours) <= 29 ) {
        nightWorkTimeMin = Number(endMinutes);
    } else {
        nightWorkTimeMin = Number(endMinutes) - Number(startMinutes) - Number(restMinutes);
    }
    // console.log('4', Number(endHours));
    // console.log('nightWorkTimeMin', nightWorkTimeMin);
    // console.log('nightWorkTime', nightWorkTime);

    if(!((startHours >= 22 || startHours <= 6 ) || ( endHours >= 22 || endHours <= 6))) { 
        return '00:00';
    } else {
        if((startHours >= 22 && endHours <= 23) || (startHours >= 22 && endHours <= 6) || ((startHours < 6 && ( endHours > startHours && (endHours === startHours ? endMinutes > startMinutes:''))) && endHours <= 6)) {
            nightWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
            if(nightWorkTime > 8) {
                if(Number(startHours) >= 22) {
                    nightWorkTime = 24 - Number(startHours);
                    if(6 - Number(endHours) > 0) {
                        nightWorkTime = nightWorkTime + (6 - Number(endHours));
                    } else {
                        nightWorkTime = nightWorkTime + 6;
                        nightWorkTimeMin = 0 - Number(startMinutes);
                    }
                } else if(Number(startHours) >= 0 && Number(startHours) <= 6 && Number(endHours) >= 7) {
                    nightWorkTime = 6 - Number(startHours);
                    if(nightWorkTime < 0) {
                        nightWorkTime = 0;
                    }
                } else {
                    nightWorkTime = 8;
                    nightWorkTimeMin = 0;
                }

            } else {
              if(nightWorkTime <= 0 && nightWorkTimeMin <= 0) {
                nightWorkTime = 0;
                nightWorkTimeMin = 0;
              }
            }

            if(nightWorkTimeMin < 0) {
                nightWorkTimeMin += 60;
                nightWorkTimeMin %= 60;
                if(nightWorkTimeMin < 0) {
                    nightWorkTimeMin += 60;
                    nightWorkTimeMin %= 60;
                }
                nightWorkTime -= 1;
            }
            
        } else {
            if(nightWorkTimeMin < 0) {
                nightWorkTimeMin += 60;
                nightWorkTimeMin %= 60;
                nightWorkTime -= 1;
                if(nightWorkTimeMin < 0) {
                    nightWorkTimeMin += 60;
                    nightWorkTimeMin %= 60;
                }
            }
            if(nightWorkTime > 8) {

                nightWorkTime = Number(endHours)-22;
                if(nightWorkTime >= 8) {
                    nightWorkTimeMin = 0;
                }

                if(nightWorkTime < 0) {
                    // nightWorkTime = 24+Number(endHours)-22;
                    nightWorkTime = Number(endHours) - Number(startHours) - Number(restHours);
                    // if(Number(endHours) > 22 && nightWorkTimeMin < )
                    if (nightWorkTime < 0 && nightWorkTimeMin < 0) {
                        nightWorkTime = 0;
                        nightWorkTimeMin = 0;
                    } 
                }   

            } else {
                if( nightWorkTime < 0 ) {
                    
                    // nightWorkTime = 8;
                    nightWorkTime = Number(endHours) - Number(startHours) - Number(restHours);
                    if(nightWorkTime <= 0 && nightWorkTimeMin >= 1) {
                        nightWorkTimeMin = 0;
                    }
                    else if(nightWorkTimeMin > 0) {
                        nightWorkTimeMin += 60;
                        nightWorkTimeMin %= 60;
                        nightWorkTime -= 1;
                    }

                } else {

                    nightWorkTime = Number(endHours)-22;
                    if(nightWorkTime < 0) {
                        if(Number(startHours) <= 6 && Number(endHours) > Number(startHours)) {
                            nightWorkTime = Number(endHours) - Number(startHours) - Number(restHours);
                        } else {
                            nightWorkTime = 24+Number(endHours)-22;
                        }
                    }
                    if(nightWorkTimeMin > 0) {
                        nightWorkTimeMin += 60;
                        nightWorkTimeMin %= 60;
                        
                    }
                    if(Number(endMinutes) < Number(startMinutes)) {
                        nightWorkTime -= 1;
                    } else if (Number(endMinutes) === Number(startMinutes) && Number(restMinutes) > 0) {
                        nightWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
                        nightWorkTimeMin += 60;
                        nightWorkTimeMin %= 60;
                        nightWorkTime -= 1;
                        // console.log('123', nightWorkTimeMin);
                    } else if(Number(endMinutes) < (Number(startMinutes) + Number(restMinutes))) {
                        nightWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
                        nightWorkTimeMin += 60;
                        nightWorkTimeMin %= 60;
                        nightWorkTime -= 1;
                        // console.log('123', nightWorkTimeMin);
                    }
                }
            }
        }
    }

    
    const hours = Math.floor(nightWorkTime).toString().padStart(2, '0');
    const minutes = Math.floor(nightWorkTimeMin).toString().padStart(2, '0');
    // const seconds = Math.floor(((nightWorkTime % 1) * 60) % 1 * 60).toString().padStart(2, '0');
    // return `${hours}:00`;
    return `${hours}:${minutes}`;

}



function calculateWorkHours(startTime, endTime, restTime) {
    
    if(!startTime || !endTime || !restTime) {
        return false;
    } 

    const [startHours, startMinutes, startSeconds] = startTime.split(":").map(String);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
    const [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
    
    let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
    let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
    let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

    const workTimeStart1 = start;
    workTimeStart1.setHours(startHours, startMinutes, startSeconds);
    
    const workTimeEnd2 = end;
    workTimeEnd2.setHours(endHours, endMinutes, endSeconds);

    const workTimeRest3 = rest;
    workTimeRest3.setHours(restHours, restMinutes, restSeconds);
 
    let totalWorkTime;
    let totalWorkTimeMin;
    if(endHours > startHours) {
        totalWorkTime = endHours - startHours - restHours;
    } else {
        totalWorkTime = 24 + ((endHours - startHours) - restHours);
        if(endHours === startHours) {
            totalWorkTime = 0;
            totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
        }
    }

    if(Number(startMinutes) === Number(endMinutes)) {
        totalWorkTimeMin = 0 - Number(restMinutes);
    } else {
        totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
    }

    if(totalWorkTimeMin > 60) { 
        totalWorkTime -= 1;
        totalWorkTimeMin %= 60;
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin = 60 - totalWorkTimeMin;
        }
        // console.log('1-1', totalWorkTime);
        // console.log('1-1', totalWorkTimeMin);
    } else if (totalWorkTimeMin === 60) { // over 60min
        totalWorkTimeMin %= 60;
        // totalWorkTime -= 1;
    } else {
        // console.log('2-1', totalWorkTime);
        // console.log('2-1', totalWorkTimeMin);
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin %= 60;
        } else {
            if(totalWorkTimeMin < 0) {
                totalWorkTimeMin = 60 + totalWorkTimeMin;
                totalWorkTime -= 1;
                if(totalWorkTimeMin < 0) {
                    totalWorkTimeMin += 60;
                    totalWorkTimeMin %= 60;
                    totalWorkTime -= 1;
                }
                if(totalWorkTime < 0) {
                    totalWorkTime = 0;
                    totalWorkTimeMin = 0;
                }
            } else {
                totalWorkTimeMin %= 60;
            }
            // console.log('3-1', totalWorkTime);
            // console.log('3-1', totalWorkTimeMin);
        }
        totalWorkTimeMin %= 60;
    }
    // console.log('4-1', totalWorkTime);
    // console.log('4-1', totalWorkTimeMin);
    
    if(totalWorkTime <= 0 && totalWorkTimeMin <= 0) { //時間確認必要
        return '00:00'; 
    } 
    
    const hours = Math.floor(totalWorkTime).toString().padStart(2, '0');
    const minutes = Math.floor(totalWorkTimeMin).toString().padStart(2, '0');

    if(hours >= 12) {
        calculateNightWorkHours(startTime, endTime, restTime);
    }

    return `${hours}:${minutes}`;

}

function calculateUnderWorkHours(startTime, endTime, restTime) {
    

    if(!startTime || !endTime || !restTime) {
        return false;
    } 

    const [startHours, startMinutes, startSeconds] = startTime.split(":").map(String);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
    const [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
    
    let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
    let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
    let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

    const workTimeStart1 = start;
    workTimeStart1.setHours(startHours, startMinutes, startSeconds);
    
    const workTimeEnd2 = end;
    workTimeEnd2.setHours(endHours, endMinutes, endSeconds);

    const workTimeRest3 = rest;
    workTimeRest3.setHours(restHours, restMinutes, restSeconds);
 
    let totalWorkTime;
    let totalWorkTimeMin;
    if(endHours > startHours) {
        totalWorkTime = endHours - startHours - restHours;
    } else {
        totalWorkTime = 24 + ((endHours - startHours) - restHours);
        if(endHours === startHours) {
            totalWorkTime = 0;
            totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
        }
    }

    if(Number(startMinutes) === Number(endMinutes)) {
        totalWorkTimeMin = 0 - Number(restMinutes);
    } else {
        totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
    }

    if(totalWorkTimeMin > 60) { 
        totalWorkTime -= 1;
        totalWorkTimeMin %= 60;
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin = 60 - totalWorkTimeMin;
        }
    } else if (totalWorkTimeMin === 60) { // over 60min
        totalWorkTimeMin %= 60;
    } else {
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin %= 60;
        } else {
            if(totalWorkTimeMin < 0) {
                totalWorkTimeMin = 60 + totalWorkTimeMin;
                totalWorkTime -= 1;
                if(totalWorkTimeMin < 0) {
                    totalWorkTimeMin += 60;
                    totalWorkTimeMin %= 60;
                    totalWorkTime -= 1;
                }
                if(totalWorkTime < 0) {
                    totalWorkTime = 0;
                    totalWorkTimeMin = 0;
                }
            } else {
                totalWorkTimeMin %= 60;
            }
        }
        totalWorkTimeMin %= 60;
    }
    
    if(totalWorkTime <= 0 && totalWorkTimeMin <= 0) { //時間確認必要
        return '00:00'; 
    } 
    
    const hours = totalWorkTime;
    const minutes = totalWorkTimeMin;
    
    // let underHours = Number(8) - Number(hours);
    // let underMinutes = Number(minutes);

    // if(underHours < 0) {
    //     alert('123')
    //     underHours = Number(0);
    //     underMinutes = Number(0);
    // } else if(underHours === 0 && minutes > 0) {
    //     underHours = Number(0);
    //     alert('456')
    // } else {
    //     underMinutes = Number(60) - Number(minutes);
    // }


    let underHours;
    let underMinutes;

    if(Number(hours) >= 8) {

        underHours = 0;
        underMinutes = 0;
    
    } else {
        
        if(Number(hours) > 0) {
            underHours = 8 - Number(hours);
            if(Number(minutes) > 0) {
                underMinutes = 60 - Number(minutes);
                if(underHours > 0) {
                    underHours = underHours - 1;
                } 
            } else {
                underMinutes = Number(minutes);
            }
        } else {
            underHours = 0;
            if(minutes > 0) {
                underMinutes = 60 - Number(minutes);
            } else {
                underMinutes = Number(minutes);
            }
        }
    
    }
    
    underHours = Math.floor(underHours).toString().padStart(2, '0');
    underMinutes = Math.floor(underMinutes).toString().padStart(2, '0');
    
    return `${underHours}:${underMinutes}`; 
    
}


function calculateOverWorkHours(endTime_tmp, endTime, startTime, startTime_tmp, restTime) {
    
    //　時間外労働は4時間を超えないこと

    if(!endTime_tmp || !endTime || !startTime || !startTime_tmp || !restTime) {
        return false;
    } 

    const nightWorkTime = calculateNightWorkHours(startTime, endTime, restTime);
    const totalWorkTime = calculateWorkHours(startTime, endTime, restTime);
    let hoursOfNightWork = nightWorkTime.split(':')[0];
    let hoursOftotalWork = totalWorkTime.split(':')[0];
    let minutesOfNightWork = nightWorkTime.split(':')[1];
    let minutesOftotalWork = totalWorkTime.split(':')[1];

    const [stHours, stMinutes, stSeconds] = startTime.split(":").map(String);
    const [startHours, startMinutes, startSeconds] = endTime_tmp.split(":").map(String);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
    const [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
    
    let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
    let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
    let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

    const overTimeStart1 = start; //endTime_tmpの時間
    overTimeStart1.setHours(startHours, startMinutes, startSeconds);
    
    const overTimeEnd2 = end; //endTimeの時間
    overTimeEnd2.setHours(endHours, endMinutes, endSeconds);

    const overTimeRest3 = rest; //restTimeの時間
    overTimeRest3.setHours(restHours, restMinutes, restSeconds);

    let overMinOfHour;
    let overMin;
    
    if(Math.floor((Number(minutesOfNightWork) + Number(minutesOftotalWork)) / 60) >= 1) {
        overMinOfHour = Math.floor((Number(minutesOfNightWork) + Number(minutesOftotalWork)) / 60);

        if(Number(minutesOfNightWork) === Number(minutesOftotalWork)) {
            overMin = 0;
        } else {
            overMin = (Number(minutesOfNightWork) - Number(minutesOftotalWork)) % 60;
        }

    } else {
        overMinOfHour = 0;

        if(Number(minutesOfNightWork) === Number(minutesOftotalWork)) {
            overMin = 0;
        } else {
            overMin = (Number(minutesOfNightWork) - Number(minutesOftotalWork)) % 60;
        }
    }
        
    let overWorkTime;
    let overWorkTimeMin;


    if((Number(hoursOftotalWork) - Number(hoursOfNightWork) - overMinOfHour) < 8) { //8時間超えない場合

        overWorkTime = 0;
        overWorkTimeMin = 0;

    } else if((Number(hoursOftotalWork) - Number(hoursOfNightWork) - overMinOfHour) === 8) {//8時間の場合(分を計算するため)
        
        overWorkTime = 0;
        overWorkTimeMin = Number(minutesOftotalWork) - Number(minutesOfNightWork);

        if(Number(minutesOftotalWork) <  Number(minutesOfNightWork)) {

            overWorkTimeMin += 60;
            overWorkTimeMin %= 60;
            
            if(overWorkTime >= 1) {
                overWorkTime -= 1;
            }

            if(Number(hoursOftotalWork) - Number(hoursOfNightWork) <= 8) {
                if(Number(minutesOftotalWork) - Number(minutesOfNightWork) <= 0) {
                    overWorkTime = 0;
                    overWorkTimeMin = 0;
                }
            } 
            
        }
 
        

        // console.log('1', overWorkTime);
        // console.log('1-1', overWorkTimeMin);
        
        
    } else { //8時間超える場合
        
        // alert('2')
        overWorkTime = (Number(hoursOftotalWork) - Number(hoursOfNightWork) - overMinOfHour) - 8;
        overWorkTimeMin = overMin;
        

        // console.log('2', overWorkTime);
        // console.log('2-1', overWorkTimeMin);

        if(overMinOfHour > 0) {
            overWorkTime += overMinOfHour;
        } 

        if(overWorkTimeMin > 60) {  // 60分を超えた時
            overWorkTimeMin %= 60;
            if(overWorkTimeMin > 0) {
                overWorkTimeMin = 60 - overWorkTimeMin;
            }
            // console.log('3', overWorkTime);
            // console.log('3-1', overWorkTimeMin);
        } else if (overWorkTimeMin === 60) { // 60分の時
            overWorkTimeMin %= 60;
            // console.log('4', overWorkTime);
            // console.log('4-1', overWorkTimeMin);
        } else {
            if(overWorkTimeMin > 0) {
                overWorkTimeMin = 60 - overWorkTimeMin;
                overWorkTime -= 1;
                // console.log('5', overWorkTime);
                // console.log('5-1', overWorkTimeMin);
            } else {
                if(overWorkTimeMin < 0) {
                    overWorkTimeMin = 60 - overWorkTimeMin;
                    overWorkTimeMin %= 60;
                    // overWorkTime -= 1;
                    // console.log('6', overWorkTime);
                    // console.log('6-1', overWorkTimeMin);
                } else {
                    overWorkTimeMin %= 60;
                    // console.log('7', overWorkTime);
                    // console.log('7-1', overWorkTimeMin);
                }
            }
        }

    }
    // console.log('8', overWorkTime);
    // console.log('8-1', overWorkTimeMin);
 
    
    const hours = Math.floor(overWorkTime).toString().padStart(2, '0');
    const minutes = Math.floor(overWorkTimeMin).toString().padStart(2, '0');
    // const seconds = Math.floor(((overWorkTime % 1) * 60) % 1 * 60).toString().padStart(2, '0');
    // return `${hours}:00`;
    return `${hours}:${minutes}`;
}
const DidWork = (props) => {
    
    const [trueFields, setTrueFields] = useState([]);
    const [year, month, day] = props.date.split("-");
    const formattedDate = `${month}/${day}`; 
    const [users, setUsers] = useState([]);
    const [template_in, setTemplate_in] = useState();
    const [template_out, setTemplate_out] = useState();
    const [template_rest, setTemplate_rest] = useState();
    const [works, setWorks] = useState([]);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [restTime, setRestTime] = useState('');
    const [overWorkTime, setOverWorkTime] = useState('00:00');
    const [nightWorkTime, setNightWorkTime] = useState('00:00');
    const [holidayWorkTime, setHolidayWorkTime] = useState('00:00');
    const [underWorkTime, setUnderWorkTime] = useState('00:00');
    const [workTime, setWorkTime] = useState('00:00');
    const [isHoliday, setIsHoliday] = useState(false);
    const [date, setDate] = useState();
    const [memo, setMemo] = useState();
    const [holidayType, setHolidayType] = useState(props.holidayType ? props.holidayType : '');


    const handleClickClose = (param) => {

        if(param === 'DidWork') {
            props.onAlertModalClose('DidWork');
        }
    };

    useEffect(() => {
        if(localStorage.users) {
            setUsers(JSON.parse(localStorage.users));
        }
        // console.log(props.date,'-',props.works);
        setTrueFields(getTrueFields(props.works));
        setWorks(props.works);
        setDate(props.date);
        setHolidayType(props.holidayType);
        if(props.works) {
            setStartTime(props.works.time_in);
            setEndTime(props.works.time_out);
            setRestTime(props.works.time_rest);
            setNightWorkTime(props.works.night_work_time);
            setOverWorkTime(props.works.over_work_time);
            setHolidayWorkTime(props.works.holiday_work_time);
            setUsers(props.users);
            setTemplate_in(props.users.template_in);
            setTemplate_out(props.users.template_out);
            setTemplate_rest(props.users.template_rest);
            setIsHoliday(props.works.is_holiday_work);
            setWorkTime(props.works.work_time);
            setMemo(props.works.memo);
            setUnderWorkTime(props.works.under_work_time);
        }
    }, [props.works, props.setIsLoading, props.date, props.users]);

    const updateWork = async(type, newTime) => {
        
        props.setIsLoading(true);

        if(type === 'start') {
            var start = newTime
        }
        if(type === 'end') {
            var end = newTime
        }
        if(type === 'rest') {
            var rest = newTime
        }

        const data = {
            'user_id' : !props.userid ? users.id : props.userid,
            'date': date,
            'time_in' : start ? start : startTime,
            'time_out' : end ? end : endTime,
            'time_rest' : rest ? rest : restTime,
            'over_work_time' : holidayType === 'overTime' ? calculateWorkHours(start ? start : startTime, end ? end : endTime, rest ? rest : restTime) : calculateOverWorkHours(template_out, end ? end : endTime, start ? start : startTime, template_in, rest ? rest : restTime),
            'night_work_time' : calculateNightWorkHours(start ? start : startTime, end ? end : endTime, rest ? rest : restTime),
            'holiday_work_time' : holidayType === 'holidayTime' ? calculateWorkHours(start ? start : startTime, end ? end : endTime, rest ? rest : restTime) : "00:00:00",
            'under_work_time': underWorkTime,
            'is_holiday_work' : isHoliday === true ||  isHoliday === 'true' ? 'true' : 'false',
            'work_time': calculateWorkHours(start ? start : startTime, end ? end : endTime, rest ? rest : restTime),
            // 'memo' : memo,
            'update_user_name' : users.family_name,
        }

        // console.log(data);

        try {
            // console.log(data);
    
            const response = await AxiosConfig.post('/api/update_worktime', data);
    
            if (response.status === 200) {
                // alert('保存しました!');
                // handleClickClose('DidWork');
                props.setIsAttendanceVisible(!props.isAttendanceVisible);
                props.setIsChange(true);
            } else {
                alert('保存失敗しました!');
            }
        } catch (error) {
            console.log(error);
            alert('保存失敗しました!');
        } finally {
            props.setIsLoading(false);
        }

    } 

    const calculateTime = () => {
        setNightWorkTime(calculateNightWorkHours(startTime, endTime, restTime));
        setOverWorkTime(calculateOverWorkHours(template_out, endTime, startTime, template_in, restTime));
        setWorkTime(calculateWorkHours(startTime, endTime, restTime));
        if(isHoliday === true) {
            setHolidayWorkTime(calculateWorkHours(startTime, endTime, restTime));
        }
    }

    // const calculateTime = useCallback(() => {
    //     setNightWorkTime(calculateNightWorkHours(startTime, endTime, restTime));
    //     setOverWorkTime(calculateOverWorkHours(template_out, endTime, startTime, template_in, restTime));
    //     setWorkTime(calculateWorkHours(startTime, endTime, restTime));
    //     if(isHoliday === true) {
    //         setHolidayWorkTime(calculateWorkHours(startTime, endTime, restTime));
    //     }
    // }, [startTime, endTime, restTime, template_in, template_out, isHoliday]);

  
    useEffect(() => {
        calculateTime();
    }, [startTime, endTime, restTime]);
  

  return (
    <>
      <div className='WorkInput_component'>
        <div>
          <span className='WorkInput_label'>出勤</span>
        </div>
        <div>
          <TimePicker
              type="start"
              value={startTime}
              calculateTime={calculateTime}
              setStartTime={setStartTime}
              updateWork={updateWork}
              page="DidWork"
              isSubmit={props.isSubmit}
          />
          {/* <input type='text' name='template_in' value={formatTime(works.time_in ? works.time_in : users.template_in)} readOnly disabled /> */}
        </div>
        <div style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '14px', color: '#f00', fontWeight: '700' }}>{ trueFields[0] }</span>
        </div>
      </div>
      <div className='WorkInput_component'>
        <div>
          <span className='WorkInput_label'>退勤</span>
        </div>
        <div>
          <TimePicker
            type="end"
            value={endTime}
            calculateTime={calculateTime}
            setEndTime={setEndTime}
            updateWork={updateWork}
            page="DidWork"
            isSubmit={props.isSubmit}
          />
          {/* <input type='text' name='template_out' value={formatTime(works.time_out ? works.time_out : users.template_out)} readOnly disabled /> */}
        </div>
        <div style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '14px', color: '#f00', fontWeight: '700' }}>{ trueFields[1] }</span>
        </div>
      </div>
      <div className='WorkInput_component'>
        <div>
          <span className='WorkInput_label'>休憩</span>
        </div>
        <div>
          <TimePicker
            type="rest"
            value={restTime}
            calculateTime={calculateTime}
            setRestTime={setRestTime}
            updateWork={updateWork}
            page="DidWork"
            isSubmit={props.isSubmit}
          />          
          {/* <input type='text' name='template_rest' value={formatTime(works.time_rest ? works.time_rest : users.template_rest)} readOnly disabled /> */}
        </div>
      </div>
      <div className='WorkInput_component'>
        <div>
          <span className='WorkInput_label'>合計労働時間</span>
        </div>
        <div>
          <TimePicker 
            type='total' 
            page="DidWork" 
            value={workTime} 
          />
          {/* <input type='text' name='workTime' value={formatTime(workTime)} className='workTime' readOnly disabled  /> */}
        </div>
      </div>
    </>
  );
};

export default DidWork;
import * as React from 'react';
import { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { jaJP } from '@mui/x-date-pickers/locales';


const SelectDate = (props) => {

    const [holidayDate, setHolidayDate] = useState(dayjs());

    useEffect(() => {
      setHolidayDate(dayjs(props.holidayDate));
    }, [props.holidayDate]);
    
    const theme = createTheme(
        {},
        jaJP, // x-date-pickers translations
    );

    return (
        <>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: "YYYY年MM月", monthShort: "MM月" }}>
                    <DatePicker
                        label=""
                        value={holidayDate} 
                        showDaysOutsideCurrentMonth
                        format="YYYY-MM-DD"
                        onChange={(date) => props.setHolidayDate(date.format('YYYY-MM-DD'))}
                        sx={{
                            // minWidth: '120px!important',
                            width: '100%!important',
                        }}
                        slotProps={{
                          textField: {
                            sx: {
                              '& input': {
                                padding: '8.5px 11px',
                                textAlign: 'right'
                              },
                            },
                          }
                        }}
                    />
                </LocalizationProvider>
            </ThemeProvider>
        </>
    );
}

export default SelectDate;
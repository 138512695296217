import React, { useState, useRef, useEffect, useContext } from 'react';
import styles from './expenseboard.module.scss'
import CustomFlatpickr from '../../components/dashboard/CustomFlatpickr';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { common } from '@mui/material/colors';
import BasicTable from '../../components/expenseboard/BasicTable';
import AdvancedTable from '../../components/expenseboard/AdvancedTable';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AxiosConfig from '../../AxiosConfig';
import './expenseboard.scss';
import { LoadingContext } from '../../LoadingContext';
import SideMenuTab from '../../components/SideMenuTab';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors'

import Submit from '../../components/expenseboard/submit';


const ExpenseBoard = () => {

  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const date = new Date();
  const initialYear = date.getFullYear();
  const initialMonth = date.getMonth() + 1;
  const [year, setYear] = useState(initialYear);
  const [month, setMonth] = useState(initialMonth);
   
  const [users, setUsers] = useState([]);
  const [expense, setExpense] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  const [userExpense, setUserExpense] = useState([]);
  const [isSubmit, setIsSubmit] = useState('');


  const getYear = (year) => {
    setYear(year);
  }
  const getMonth = (month) => {
    setMonth(month);
  }
  const [start, setStart] = useState();

  const handleDateChange = (selectedDate) => {
    setStart(selectedDate);
  };

  const groupDataByUserId = (data) => {
    const grouped = data.reduce((acc, item) => {
      const { user_id, family_name, amount } = item; // amountを追加
      if (!acc[user_id]) {
        acc[user_id] = { name: family_name, data: [], totalAmount: 0 }; // totalAmountを初期化
      }
      acc[user_id].data.push(item);
      acc[user_id].totalAmount += amount; // 金額を合計
      return acc;
    }, {}); 
  
    return Object.keys(grouped).map(user_id => ({
      user_id: parseInt(user_id),
      name: grouped[user_id].name,
      data: grouped[user_id].data,
      totalAmount: grouped[user_id].totalAmount // 合計金額を追加
    }));
  };

  const getCloseExpense = () => {
    const data = {
      'year': year,
      'month': month,
    };
    // console.log(data);
    AxiosConfig.get('/api/getCloseExpense', { params: data })
    .then((res) => {
      // console.log(res);
      setIsSubmit( res.data.res.data ? true : false);
    }).catch(error => {
        alert('エラー');
        console.log(error);
    }); 
  };

	useEffect(() => {

    setIsLoading(true);
    setExpense([]);
    let owner;
    if(localStorage.users) {
      setUsers(JSON.parse(localStorage.users));
      owner = JSON.parse(localStorage.users);
    }
    getCloseExpense();
    const data = {
      'year': year,
      'month': month,
    };
    setYear(year);
    setMonth(month);
    // console.log('data', data);
    AxiosConfig.get('/api/getExpense', { params: data })
    .then((res) => {
      // console.log(res);
      setExpense(res.data.res.data);
    }).catch(error => {
        alert('エラー');
        console.log(error);
    }).finally(() => {
      setIsLoading(false);
      setIsRefresh(false);
    });
  }, [year, month, isRefresh]);
  
  useEffect(() => {
    setUserExpense(groupDataByUserId(expense))
  }, [expense]);

  const flatpickrRef = useRef();

  const handleClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.focus();
      flatpickrRef.current.click(); 
    }
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  }));


  const admin_expense_every_ok = async(year, month) => {

    const data = {
      'year': year,
      'month' : month,
    }

    if (window.confirm(year+"年"+month+"月の経費申請を全て承認しますか？")) {
    
      try {
        const response = await AxiosConfig.post('/api/expense_admin_month_ok', data);

        if (response.status === 200) {
          setIsRefresh(true);
        } else {
          alert('保存失敗しました。');
        }
      } catch (error) {
        console.log(error);
        alert('保存失敗しました。');
      }

    }

  }


  return (
    <>
      { isLoading && (
        <div className="loading-container" style={{ zIndex:'999'}}>
          {/* <img src="https://596576bca7dcb637.main.jp/tool/image/loading_bar.gif" alt="loading" /> */}
          <img src="https://596576bca7dcb637.main.jp/tool/image/loading-bar.gif" alt="loading" style={{ width: '50px' }} />
        </div>
      )}
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.header_datepicker}>
            <CustomFlatpickr onDateChange={handleDateChange} getYear={getYear} getMonth={getMonth} ref={flatpickrRef}  />
            <ArrowDownwardRoundedIcon sx={{ color : common.white }} fontSize="large" onClick={handleClick} />
          </div>
          <div className={styles.header_title}>
            <h2>{year}年{month}月 未支払い分 経費申請</h2>
          </div>
          <div className={styles.header_button}>
            <ColorButton variant="contained"  onClick={() => admin_expense_every_ok(year, month)}>全て承認</ColorButton>
              {/* <button className={styles.edit}>戻る</button> */}
            <SideMenuTab usertype={users.admin_type} />
          </div>   
        </header>
        <main className={styles.main_content}>
          {/* <section className={styles.section + ' ' + styles.section_left}> */}
            {/* <div className={styles.BasicTableDiv} style={{padding: '1em', display: 'flex', overflowX: 'auto', marginBottom: '29em' }}> */}
            {/* <div className={styles.BasicTableDiv} style={{padding: '1em', display: 'flex', overflowX: 'auto', minHeight: '86.9vh' }}>
            <BasicTable expense={expense} year={year} month={month} setIsRefresh={setIsRefresh} userExpense={userExpense}/>
            </div>
          </section> */}
          <section className={styles.section + ' ' + styles.section_left}>
            {/* <div className={styles.BasicTableDiv} style={{padding: '1em', display: 'flex', overflowX: 'auto', marginBottom: '29em' }}> */}
            <div className={styles.BasicTableDiv} style={{padding: '1em', display: 'flex', overflowX: 'auto', minHeight: '86.9vh' }}>
            {/* <BasicTable expense={expense} year={year} month={month} setIsRefresh={setIsRefresh} /> */}
            <AdvancedTable expense={expense} year={year} month={month} setIsRefresh={setIsRefresh} userExpense={userExpense}/>
            </div>
          </section>
        </main>
        <footer className={styles.footer}>
          <Submit year={year} month={month} users={users} isSubmit={isSubmit} setIsSubmit={setIsSubmit} />
        </footer>
      </div>
    </>
  );
};

export default ExpenseBoard;


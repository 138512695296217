import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../pages/document/document.module.scss';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import AxiosConfig from '../../AxiosConfig';
import SelectBox from './SelectBox';
import SelectDate from './SelectDate';
import dayjs from 'dayjs';
import InputFileUpload from './InputFileUpload';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2, minWidth: '200px' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  } 

const DocumentFormModal = (props) => {

    const handleClickClose = (param) => {
        // if(param === 'new') {
        //     setTimeout(() => {
        //         props.setIsLoading(true);
        //         props.setIsRefresh(true);
        //     }, 300);
        // }
        props.onAlertModalClose();
    };

    // const [users, setUsers] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [details, setDetails] = useState('');
    const [fileName, setFileName] = useState();
    // const [file, setFile] = useState('');

    useEffect(() => {
        // console.log(props);
        // console.log('fileName', fileName);
        if(props.data) {
            setId(props.data.id);
            setTitle(props.data.title);
            setDetails(props.data.details);
            setFileName(props.data.filename);
        }

    }, [])
    
    const createDocument = async() => {

        let owner;
        if(localStorage.users) {
          owner = JSON.parse(localStorage.users);
        }
        // console.log(fileName);

        if(title === '' || !title) {
            alert('タイトルを入力してください。');
        } else if(fileName === '' || !fileName) {
            alert('ファイルをアップロードしてください。');
        } else {
            
            if(id > 0) {

                const data = {
                    'idx' : props.data.id,
                    'title' : title,
                    'details' : details,
                    'filename' : fileName, 
                    'family_name' : owner.family_name,
                }

                // console.log(data);

        
                try {
            
                    const response = await AxiosConfig.post('/api/update_document', data);
            
                    if (response.data.res.code === 'success') {
                        // console.log(response);
                        // alert('保存しました!');
                        handleClickClose();
                        props.setIsRefresh(true);
                    } else {
                        alert('保存失敗しました!');
                    }
                } catch (error) {
    
                    console.log(error);
                    alert('保存失敗しました!');
    
                }
    
            } else {
    
                const data = {
                    'title' : title,
                    'details' : details,
                    'filename' : fileName, 
                    'family_name' : owner.family_name,
                }
                // console.log(data);
                try {
            
                    const response = await AxiosConfig.post('/api/create_document', data);
            
                    if (response.data.res.code === 'success') {
                        // console.log(response);
                        // alert('保存しました!');
                        handleClickClose();
                        props.setIsRefresh(true);
                    } else {
                        alert('保存失敗しました!');
                    }
                } catch (error) {
                    console.log(error);
                    alert('保存失敗しました!');
                }
            }
            
        }


    } 


    return (
    <>
        <BootstrapDialog
            onClose={handleClickClose}
            aria-labelledby="customized-dialog-title"
            open={props.isOpen}
            >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickClose}>
                {id > 0 ? '書類編集':'書類登録'}
            </BootstrapDialogTitle>
            <DialogContent dividers >
                <div className={styles.main_div}>
                    <div className={styles.main_line_div}>
                        <div className={styles.line_title_div}>
                            タイトル
                        </div>
                        <div className={styles.line_time_div}>
                            <Input
                                value={title}
                                inputProps={{
                                    sx: {
                                        textAlign: "right",
                                        "&::placeholder": {
                                        textAlign: "right",
                                        },
                                    },
                                }}
                                onChange={(e) => setTitle(e.target.value)}
                            />   
                        </div>
                    </div>
                    <div className={styles.main_line_div}>
                        <div className={styles.line_title_div}>
                            ファイル
                        </div>
                        <div className={styles.line_time_div}>
                            <InputFileUpload fileName={fileName} setFileName={setFileName} />
                        </div>
                    </div>
                </div>
                <div className={styles.main_div}>
                    <TextField
                        id="outlined-multiline-static"
                        label="備考"
                        value={details}
                        multiline
                        rows={3}
                        style={{ width : '100%' }}
                        onChange={(e) => setDetails(e.target.value)}
                    />
                </div>
                <div className={styles.main_div}>
                    <Button variant="outlined" fullWidth={true} onClick={createDocument}>登録</Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    </>
  );
}

export default React.memo(DocumentFormModal);
import '../../pages/join/join.scss';
import ApplicationLogo from '../../components/join/ApplicationLogo';
import JoinInputForm from '../../components/join/JoinInputForm';

function Join() {
  
  return (
    <>
      <div className="join-form-container">
        <div className="join-form">
            <div className="text-center">
                <ApplicationLogo className="w-50" />
            </div>
            <h1>アールツー</h1>
            <JoinInputForm />
        </div>
      </div>
    </>
  );
}

export default Join;
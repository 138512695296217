import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import "./css/css.scss";



const TimePicker = (props) => {

    const [value, setValue] = useState(dayjs('2000-01-01T'+props.value));

    useEffect(() => {
        setValue(dayjs('2000-01-01T'+props.value));
        // console.log(value)
    }, [props.value])
    
    return (
        <>
            <div className={props.page !== 'DidWork' ? 'timepicker' : 'timepick'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                        <MuiTimePicker
                            value={value}
                            ampm={false}
                            minutesStep={15}
                            timeSteps={{ hours: 1, minutes: 15 }}
                            maxTime={props.type === 'rest' ? dayjs().set('hour', 9) : ''}
                            readOnly={props.type === 'total' || props.isSubmit === true ? 'readOnly' : ''}
                            views={['hours', 'minutes']}
                            onChange={(newValue) => {
                                const selectedTime = newValue.format('HH:mm');
                                let hours = selectedTime.slice(0, 2);
                                let minutes = selectedTime.slice(3);

                                if (minutes !== "00" && minutes !== "15" && minutes !== "30" && minutes !== "45") {
                                    minutes = "00";
                                }

                                let newTime = hours + ':' + minutes + ':00';
                                setValue(dayjs('2000-01-01T'+newTime));

                                if(props.type === 'start') {
                                    props.setStartTime(newTime);
                                } 
                                
                                if(props.type === 'end') {
                                    props.setEndTime(newTime);
                                } 
                                
                                if(props.type === 'rest') {
                                    if(Number(hours) > 9) { // 4時間以上入力防止
                                        hours = "01";
                                        newTime = hours + ':' + minutes + ':00';
                                        setValue(dayjs('2000-01-01T'+newTime));
                                    }
                                    props.setRestTime(newTime);
                                }

                                if(props.page !== 'DidWork') {
                                    props.calculateTime();
                                } else {
                                    props.updateWork(props.type, newTime);
                                }
                            }}
                            slotProps={{
                                textField: {
                                    readOnly: true,
                                },
                            }}
                        />
                    </DemoItem>
                </LocalizationProvider>
            </div>
        </>
    );
}


export default TimePicker;
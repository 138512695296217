import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
// import { purple, blue } from '@mui/material/colors';

import WorkFormModal from './WorkFormModal';
import HolidayFormModal from "./HolidayFormModal";
import DidWork from "./DidWork";
import DidRest from "./DidRest";
import "./css/css.scss";
import AttiFormModal from "./AttiFormModal";
import AxiosConfig from "../../AxiosConfig";
// import Alert from "../Alert";

// setIsAttendanceVisible(props.isAttendanceVisible); 何も無いことに仮面切り替えが必要な場合
// setIsAttendanceVisible(!props.isAttendanceVisible); 何かのデータを入れて仮面切り替えが必要な場合

function calculateUnderWorkHours(startTime, endTime, restTime) {
    

    if(!startTime || !endTime || !restTime) {
        return false;
    } 

    const [startHours, startMinutes, startSeconds] = startTime.split(":").map(String);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
    const [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
    
    let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
    let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
    let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

    const workTimeStart1 = start;
    workTimeStart1.setHours(startHours, startMinutes, startSeconds);
    
    const workTimeEnd2 = end;
    workTimeEnd2.setHours(endHours, endMinutes, endSeconds);

    const workTimeRest3 = rest;
    workTimeRest3.setHours(restHours, restMinutes, restSeconds);
 
    let totalWorkTime;
    let totalWorkTimeMin;
    if(endHours > startHours) {
        totalWorkTime = endHours - startHours - restHours;
    } else {
        totalWorkTime = 24 + ((endHours - startHours) - restHours);
        if(endHours === startHours) {
            totalWorkTime = 0;
            totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
        }
    }

    if(Number(startMinutes) === Number(endMinutes)) {
        totalWorkTimeMin = 0 - Number(restMinutes);
    } else {
        totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
    }

    if(totalWorkTimeMin > 60) { 
        totalWorkTime -= 1;
        totalWorkTimeMin %= 60;
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin = 60 - totalWorkTimeMin;
        }
    } else if (totalWorkTimeMin === 60) { // over 60min
        totalWorkTimeMin %= 60;
    } else {
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin %= 60;
        } else {
            if(totalWorkTimeMin < 0) {
                totalWorkTimeMin = 60 + totalWorkTimeMin;
                totalWorkTime -= 1;
                if(totalWorkTimeMin < 0) {
                    totalWorkTimeMin += 60;
                    totalWorkTimeMin %= 60;
                    totalWorkTime -= 1;
                }
                if(totalWorkTime < 0) {
                    totalWorkTime = 0;
                    totalWorkTimeMin = 0;
                }
            } else {
                totalWorkTimeMin %= 60;
            }
        }
        totalWorkTimeMin %= 60;
    }
    
    if(totalWorkTime <= 0 && totalWorkTimeMin <= 0) { //時間確認必要
        return '08:00'; 
    } 
    
    const hours = totalWorkTime;
    const minutes = totalWorkTimeMin;
    
    // let underHours = Number(8) - Number(hours);
    // let underMinutes = Number(minutes);

    // if(underHours < 0) {
    //     alert('123')
    //     underHours = Number(0);
    //     underMinutes = Number(0);
    // } else if(underHours === 0 && minutes > 0) {
    //     underHours = Number(0);
    //     alert('456')
    // } else {
    //     underMinutes = Number(60) - Number(minutes);
    // }


    let underHours;
    let underMinutes;

    if(Number(hours) >= 8) {

        underHours = 0;
        underMinutes = 0;
    
    } else {
        
        if(Number(hours) >= 0) {
            underHours = 8 - Number(hours);
            if(Number(minutes) > 0) {
                underMinutes = 60 - Number(minutes);
                if(underHours > 0) {
                    underHours = underHours - 1;
                    // console.log(underHours);
                } 
            } else {
                underMinutes = Number(minutes);
            }
        } else {
            underHours = 0;
            if(minutes > 0) {
                underMinutes = 60 - Number(minutes);
            } else {
                underMinutes = Number(minutes);
            }
        }
    
    }
    
    underHours = Math.floor(underHours).toString().padStart(2, '0');
    underMinutes = Math.floor(underMinutes).toString().padStart(2, '0');
    
    return `${underHours}:${underMinutes}`; 
    
}


const MyListMenu = (props) => {

    const GreenButton = styled(Button)(({ theme }) => ({
        color: '#fff',
        backgroundColor: '#00E6A7',
        '&:hover': {
          backgroundColor: '#00CC94',
        },
    }));
    const GrayButton = styled(Button)(({ theme }) => ({
        color: '#fff',
        backgroundColor: '#d8d8d8',
        '&:hover': {
            backgroundColor: '#bfbfbf',
        },
    }));
    const [isWorkModalOpen, setIsWorkModalOpen] = useState(false);
    const [isAttiModalOpen, setIsAttiModalOpen] = useState(false);
    const [isHolidayModalOpen, setIsHolidayModalOpen] = useState(false);
    const [isAttendanceVisible, setIsAttendanceVisible] = useState(false);
    const [isWork, setIsWork] = useState(false);
    const [works, setWorks] = useState(false);
    const [memo, setMemo] = useState(false);
    const [restCode, setRestCode] = useState(false);
    const [holiday, setHoliday] = useState(false);
    const [holidayStates, setHolidayStates] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [userid, setUserId] = useState(props.userid ? props.userid : '');
    const [underWorkTime, setUnderWorkTime] = useState(props.timeIn ? calculateUnderWorkHours(props.timeIn, props.timeOut, props.timeRest) : '00:00');
    // const isHoliday = Object.keys(props.holiday).includes(props.date);
    const isHoliday = Object.keys(props.holiday).includes(props.date);
    const isOtherDay = Object.keys(props.otherDay).includes(props.date);
    const isHolidayState = props.holiday[props.date] ? props.holiday[props.date][1] : '';
    const dateStyle =
    props.weekday === "土"
      ? 'list_menu_item_blue'
      : props.weekday === "日"
      ? 'list_menu_item_red' 
      : isHoliday && isHolidayState === 1
      ? 'list_menu_item_holiday'
      : isHoliday && isHolidayState === 2 
      ? 'list_menu_item_notice'
      : isHoliday && isHolidayState === 3 
      ? 'list_menu_item_expire'
      : isHoliday && isHolidayState === 4 
      ? 'list_menu_item_etc'
      : isHoliday && isHolidayState === '' 
      ? 'list_menu_item'
      : "";
    const otherDateStyle = 
    isOtherDay ? 'list_menu_item_notice' : '' ;
    const [holidayType, setHolidayType] = useState(props.weekday === "土"|| isHoliday ? 'overTime' : props.weekday === "日" ? 'holidayTime': '');
    const [year, month, day] = props.date.split("-");
    const [isChange, setIsChange] = useState(false);
    const formattedDate = `${month}/${day}`; 

    const WorkClickOpen = () => {
        setIsWorkModalOpen(true);
    };
    const AttiClickOpen = () => {
        setIsAttiModalOpen(true);
    };
    const HolidayClickOpen = () => {
        setIsHolidayModalOpen(true);
    };
    const handleClose = (text) => {
        setIsWorkModalOpen(false);
        setIsAttiModalOpen(false);
        setIsHolidayModalOpen(false);
        if(text === 'rest') {
            props.setIsAttendanceVisible(!props.isAttendanceVisible);
            setIsWork(false);
        } 
        if(text === 'work') {
            props.setIsAttendanceVisible(!props.isAttendanceVisible);
            setIsWork(true);
        }
        if(text === 'DidWork') {
            props.setIsAttendanceVisible(!!props.isAttendanceVisible);
            setIsWork(true);
        }

    };

    useEffect(() => {
        setIsAttendanceVisible(props.isAttendanceVisible);
        setWorks(!!props.works);
        setUserId(props.userid);
        setHolidayStates(props.holiday[props.date] ? props.holiday[props.date][1] : '');
        setHolidayType(props.weekday === "土"|| isHoliday ? 'overTime' : props.weekday === "日" ? 'holidayTime': '');
        if (props.works && props.works.hasOwnProperty('is_work')) {
            setIsWork(props.works.is_work);
            props.setIsStatus(true);
            // setRestCode(restCode);
            setRestCode(props.works.rest_code);
            // setUnderWorkTime(calculateUnderWorkHours(props.works.time_in,props.works.time_out,props.works.time_rest));
            // console.log(props.works.date, props.works);
        }

        setUnderWorkTime(calculateUnderWorkHours(props.users['template_in'],props.users['template_out'],props.users['template_rest']));
        
        setHoliday(props.weekday === "土" || props.weekday === "日" || isHoliday ? true : false);

        props.setIsHoliday(props.weekday === "土" || props.weekday === "日" || isHoliday ? true : false);
        setHoliday(props.weekday === "土" || props.weekday === "日" || isHoliday ? true : false);
    }, [props.works, isAttendanceVisible, isWork, memo, restCode, isHoliday, props.weekday, props.setIsHoliday, props.setIsStatus]);
    
    // const calculateTime = () => {
    //     setUnderWorkTime(calculateUnderWorkHours(startTime, endTime, restTime));
    // }

    const DeleteWork = async() => {

        const users = props.users;

        if(props.isSubmit === true) {

            alert('提出済みです。')
            return false;
      
        } else {

            try {
                const data = {
                    user_id : !props.userid ? users.id : props.userid,
                    date: props.date,
                    family_name: users.family_name,
                };

                // console.log(data);
                const response = await AxiosConfig.post('/api/delete_worktime', data);
                if (response.data.res.code === 'success') {
                    // alert('削除しました!');
                    // console.log('delete', response);
                    props.setIsAttendanceVisible(!props.isAttendanceVisible);
                    setRestCode('');
                } else {
                    alert(response.data.res.msg);
                }
            } catch (error) {
                console.log(error);
                alert('削除失敗しました');
            }
        }
    };

    const noticeWhatDay = (props) => {
        if(props !== '') {
            alert(props);
        }
    }


    const workTimeSave = async() => {

        setIsLoading(true);

        const users = props.users;

        let owner;
        if(localStorage.users) {
          owner = JSON.parse(localStorage.users);
        }
        
        const data = {
            user_id : !props.userid ? users.id : props.userid,
            date: props.date,
            is_work : 'true',
            is_holiday_work: holiday,
            is_holiday_work_status: holidayStates,
            holidayType: holidayType,
            update_user_name : owner.family_name !== users.family_name ? owner.family_name : users.family_name,
            under_work_time: underWorkTime,
        };
        // alert(underWorkTime);
        // if (window.confirm("出勤処理しますか？")) {
        // } 
        if(props.isSubmit === true) {

            alert('提出済みです。')
            return false;
      
        } else {

            try {   
                const response = await AxiosConfig.post('/api/create_worktime', data);
                if (response.status === 200) {
                    // alert('登録完了しました!');
                    props.setIsAttendanceVisible(!props.isAttendanceVisible);
                    setIsWork(true);
                } else {
                    alert('登録失敗しました。');
                }
            } catch (error) {
                console.log(error);
                alert('登録中にエラーが発生しました。');
            } finally { 
                setIsLoading(false);
            }
        }
    };

    return (
            <>
                <div className={`list_menu_item ${dateStyle}`} key={props.date}>
                        {
                            works ? (
                                <>
                                    <SwipeToRevealActions
                                            actionButtons={[
                                                {
                                                    content: (
                                                        isWork && props.isSubmit !== true ? (
                                                            <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                                                <GreenButton variant="outlined" size="medium">編集</GreenButton>
                                                            </div>
                                                        ) : null
                                                    ),
                                                    onClick: () => isWork && WorkClickOpen(),
                                                },
                                                {
                                                    content: (
                                                        isWork && props.isSubmit !== true ? (
                                                            <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                                                <GreenButton variant="outlined" size="medium">勤怠</GreenButton>
                                                            </div>
                                                        ) : null
                                                    ),
                                                    onClick: () => isWork && AttiClickOpen(),
                                                },
                                                {
                                                    content: (
                                                        <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                                            <GrayButton variant="outlined" size="medium">削除</GrayButton>
                                                        </div>
                                                    ),
                                                    onClick: () => DeleteWork(),
                                                },
                                            ]}
                                            actionButtonMinWidth={90}
                                            height={90}
                                            hideDotsButton={!works || props.isSubmit === true}
                                        >
                                        <div style={{ display: 'inline-flex', flexDirection: 'column', textAlign: 'center', padding: '0 0.3em', fontSize: '14px' }}>
                                            <span className={`list_menu_item ${dateStyle}`}>{ formattedDate }</span>
                                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <span className={`list_menu_item ${dateStyle}`} style={{display: 'flex', alignItems: 'center'}}>({ props.weekday })</span>
                                                </div>
                                                <div style={{display: 'inline-flex', alignItems: 'center', marginLeft: '2px'}}>
                                                    {
                                                        dateStyle === "list_menu_item_holiday" || dateStyle === "list_menu_item_notice" || dateStyle === "list_menu_item_expire" || dateStyle === "list_menu_item_etc"  ? (
                                                            <img src="https://596576bca7dcb637.main.jp/tool/image/exclamation_mark.png" style={{width: '15px', cursor: 'pointer'}} onClick={() => noticeWhatDay(props.holiday[props.date][0] ? props.holiday[props.date][0] : '')} />
                                                        ) : ''
                                                    }
                                                    {
                                                        otherDateStyle  ? (
                                                            <img src="https://596576bca7dcb637.main.jp/tool/image/exclamation_mark.png" style={{width: '15px', cursor: 'pointer'}} onClick={() => noticeWhatDay(props.otherDay[props.date][0] ? props.otherDay[props.date][0] : '')} />
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                        </div> 
                                    <div style={{ display: 'inline-flex', textAlign: 'left', padding: '0', whiteSpace: 'nowrap', width: 'calc(80% - 26px)' }}>
                                        {
                                            isWork === true ? (
                                                <DidWork works={props.works} users={props.users} date={props.date} userid={userid} setIsLoading={props.setIsLoading} holidayType={holidayType} isAttendanceVisible={isAttendanceVisible} setIsAttendanceVisible={setIsAttendanceVisible} onAlertModalClose={handleClose} setIsChange={setIsChange} isSubmit={props.isSubmit} />
                                            ) : 
                                            (
                                                <DidRest works={props.works} restCode={restCode} memo={memo} userid={userid} HolidayClickOpen={HolidayClickOpen} isSubmit={props.isSubmit}  />
                                            )
                                        }
                                    </div>
                                    </SwipeToRevealActions>
                                </>
                            ) : (
                                <>
                                    <div className="rstra-container" style={{ height: '90px' }}> 
                                        <div>
                                            <div className="rstra-content-container" style={{ height: '90px' }}>
                                                <div style={{ display: 'inline-flex', flexDirection: 'column', textAlign: 'center', padding: '0 0.3em', fontSize: '14px' }}>
                                                    <span className={`list_menu_item ${dateStyle}`}>{ formattedDate }</span>
                                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                                        <div style={{display: 'inline-flex'}}>
                                                            <span className={`list_menu_item ${dateStyle}`} style={{display: 'flex', alignItems: 'center'}}>({ props.weekday })</span>
                                                        </div>
                                                        <div style={{display: 'inline-flex', alignItems: 'center', marginLeft: '2px'}}>
                                                            {
                                                                dateStyle === "list_menu_item_holiday" || dateStyle === "list_menu_item_notice" || dateStyle === "list_menu_item_expire" || dateStyle === "list_menu_item_etc"  ? (
                                                                    <img src="https://596576bca7dcb637.main.jp/tool/image/exclamation_mark.png" style={{width: '15px', cursor: 'pointer'}} onClick={() => noticeWhatDay(props.holiday[props.date][0] ? props.holiday[props.date][0] : '')}/>
                                                                ) : ''
                                                            }
                                                            {
                                                                otherDateStyle ? (
                                                                    <img src="https://596576bca7dcb637.main.jp/tool/image/exclamation_mark.png" style={{width: '15px', cursor: 'pointer'}} onClick={() => noticeWhatDay(props.otherDay[props.date][0] ? props.otherDay[props.date][0] : '')} />
                                                                ) : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div style={{ display: 'inline-flex', textAlign: 'left', padding: '0', whiteSpace: 'nowrap', width: 'calc(80% - 26px)' }}>
                                                    <div className='DidRest_component'>
                                                        <div>
                                                            <Button variant="outlined" size="medium" onClick={workTimeSave} disabled={isLoading}>出勤</Button>
                                                        </div>
                                                    </div>
                                                    <div className='DidRest_component'>
                                                        <div>
                                                            {
                                                                    props.weekday !== "土" && props.weekday !== "日" && isHolidayState !== 1 ? (
                                                                    <Button variant="outlined" size="medium" color="error" onClick={HolidayClickOpen}>休み</Button>
                                                                ) : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='DidRest_component'>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <HolidayFormModal isOpen={isHolidayModalOpen} onAlertModalClose={handleClose} date={props.date} weekday={props.weekday} works={isWork === false ? works : ''} setIsWork={setIsWork} setMemo={setMemo} userid={userid} isAttendanceVisible={isAttendanceVisible} setIsAttendanceVisible={setIsAttendanceVisible} restCode={restCode} setRestCode={setRestCode}  />
                        <WorkFormModal isOpen={isWorkModalOpen} onAlertModalClose={handleClose} date={props.date} weekday={props.weekday} works={props.works} users={props.users} userid={userid} holidayType={holidayType} isAttendanceVisible={isAttendanceVisible} setIsAttendanceVisible={setIsAttendanceVisible} isChange={isChange} setIsChange={setIsChange} />
                        <AttiFormModal isOpen={isAttiModalOpen} onAlertModalClose={handleClose} date={props.date} weekday={props.weekday} works={ isWork && isWork === true ? props.works : ''} userid={userid} holidayType={holidayType} />
                </div>
            </>
    );
};

export default MyListMenu;
import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/l10n/ja.js';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
import { useLocation } from 'react-router-dom';

const CustomFlatpickr = forwardRef((props, ref) => {
  // alert(props.year);
  // alert(props.month);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const defaultDate = new Date(currentYear, currentMonth);
  const flatpickrRef = useRef();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const paramsyear = queryParams.get('year');
  const paramsmonth = queryParams.get('month');
  const paramsuserid = queryParams.get('id');

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (flatpickrRef.current) {
        flatpickrRef.current.flatpickr.open();
      }
      // console.log(flatpickrRef);
    },
    click: () => {
      if (flatpickrRef.current) {
        flatpickrRef.current.flatpickr.open();
      }
    },
  }));

  let monthOptions;

  if(paramsyear) {
    const setDate = new Date(Number(paramsyear), Number(paramsmonth) - 1);
    
    monthOptions = {
      locale: "ja",
      disableMobile: "true",
      defaultDate: setDate,
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: "20y年m月",
          altFormat: "F Y",
          defaultDate: "month",
        }),
      ],
    };

  } else {
    monthOptions = {
      locale: "ja",
      disableMobile: "true",
      defaultDate: defaultDate,
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: "20"+"y年m月", 
          altFormat: "F Y", 
          defaultDate: "month",
        }),
      ],
    };
  }


  return (
    <Flatpickr
      ref={flatpickrRef}
      options={monthOptions}
      onChange={(_, selectedDate) => {
          props.onDateChange(selectedDate);
          props.getYear(flatpickrRef.current.flatpickr.currentYear);
          props.getMonth(flatpickrRef.current.flatpickr.currentMonth+1);

          // urlParams.set('year', flatpickrRef.current.flatpickr.currentYear);
          // urlParams.set('month', flatpickrRef.current.flatpickr.currentMonth+1);

          // const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
          // history.push(newUrl);
      }}
    />
  );
});

export default CustomFlatpickr;
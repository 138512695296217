import * as React from 'react';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useState } from 'react';
import AxiosConfig from '../../AxiosConfig';

const Submit = (props) => {

  const [users, setUsers] = useState([]);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  
  useEffect(() => {
    setUsers(props.users);
    setYear(props.year);
    setMonth(props.month);
  }, [props.users, props.year, props.month]);

  const submitMonthExpense = async() => {

    if(props.isSubmit === true) {
      const data = {
        'user_id' : users.id,
        'date' : year+'-'+month,
        'year': year,
        'month' : month,
        'create_user_name':users.family_name,
      }
      if(!window.confirm('すでに締め切り済みです。編集可能にしますか？')){
        return;
      }
      try {
  
        const response = await AxiosConfig.post('/api/delete_expense_close', data);
        // console.log(response);
        if (response.status === 200) {
          if(response.data.res.code === 'success') {
            // alert(response.data.res.msg);
            props.setIsSubmit(false);
          } else {
            alert(response.data.res.msg);
          }
          // console.log(response);
        } else {
            alert('提出失敗しました。');
        }
    } catch (error) {
        console.log(error);
        alert('提出失敗しました。');
    }

    } else {
      const data = {
        'user_id' : users.id,
        'date' : year+'-'+month,
        'year': year,
        'month' : month,
        'create_user_name':users.family_name,
      }
      if (window.confirm("経費申請を締め切りますか？")) {

        try {
  
            const response = await AxiosConfig.post('/api/insert_expense_close', data);
             console.log(response);
            if (response.status === 200) {
              if(response.data.res.code === 'success') {
                // alert(response.data.res.msg);
                props.setIsSubmit(true);
              } else {
                alert(response.data.res.msg);
              }
              // console.log(response);
            } else {
                console.log('test');
                alert('提出失敗しました。');
            }
        } catch (error) {
            console.log(error);
            alert('提出失敗しました。');
        }
      } 
    } 
  }

  return (
    <>
        <Button variant="contained" color="error" fullWidth={true} size="large" onClick={submitMonthExpense} >{props.isSubmit === true ?'経費申請締め切り済み' : '経費申請締め切り'}</Button>
    </>
  );
}

export default Submit;
import axios from 'axios';

let baseURL;

if (window.location.hostname === "localhost") {
  baseURL = 'http://localhost:5173';
} else {
  baseURL = 'https://intraback.r2corp.co.jp';
}


const AxiosConfig = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

export default AxiosConfig;
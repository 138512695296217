import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import styles from '../../pages/expense/expense.module.scss';
import ExpenseFormModal from "./ExpenseFormModal";


// import { purple, blue } from '@mui/material/colors';
import AxiosConfig from "../../AxiosConfig";



const MyExpenseList = (props) => {

    const GreenButton = styled(Button)(({ theme }) => ({
        color: '#fff',
        backgroundColor: '#00E6A7',
        '&:hover': {
          backgroundColor: '#00CC94',
        },
    }));
    const GrayButton = styled(Button)(({ theme }) => ({
        color: '#fff',
        backgroundColor: '#d8d8d8',
        '&:hover': {
            backgroundColor: '#bfbfbf',
        },
    }));

    
    const [data, setData] = useState(props.data);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paymentDate, setPaymentDate] = useState();
    const [isApproval, setIsApproval] = useState(false);

    useEffect(() => {
        setData(props.data);
        // console.log(props);
        setPaymentDate(data.month);
        setIsApproval(props.data.admin_ok_date !== null)
    }, [props.isRefresh, props.data]);

    
    const ExpenseClickOpen = (param) => {
        if(param){
            alert("承認済みのため編集できません。");
            return;
        }
        setIsModalOpen(true);
    };
    const handleClose = () => {
        setIsModalOpen(false);
        props.setIsRefresh(false);
    };

    const formatStartDate = (dateString,end) => {
        if (dateString){
            let texta = '利用開始日:';
            const date = new Date(dateString);
            if(end){
                date.setMonth(date.getMonth() + 1);
                date.setDate(date.getDate() - 1);
                texta = '利用終了日:';
            }
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1
            const day = String(date.getDate()).padStart(2, '0');
            return texta+`${year}-${month}-${day}`;
        }else{
            return '';
        }
    };

    const DeleteExpense = async(param1, param2 ,param3) => {

        // const users = props.users;
        if(param3){
            alert("承認済みのため削除できません。");
            return;
        }
        if(!window.confirm('削除します。よろしいですか？')){
            return;
        }
        try {
            
            const data = {
                user_id : param1,
                idx: param2,
            };
            
            // console.log(data);

            const response = await AxiosConfig.post('/api/delete_expense', data);
            if (response.data.res.code === 'success') {

                // alert('削除しました!');
                props.setIsRefresh(true);

            } else {

                alert(response.data.res.msg);

            }
            
        } catch (error) {

            console.log(error);
            alert('削除失敗しました');

        }
    };



    
    return (
        <>
            <div className={`list_menu_items`} key={props.date} style={{ borderBottom: '1.5px solid #ddd', padding: '0 0 0.5em 0' }}>
                <SwipeToRevealActions
                    actionButtons={
                        !props.isDisabled && data.status !== 3 ? [{
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                    <GreenButton variant="outlined" size="medium">編集</GreenButton>
                                </div>
                          ),
                          onClick: () => ExpenseClickOpen(isApproval),
                        },
                        {
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                    <GrayButton variant="outlined" size="medium">削除</GrayButton>
                                </div>
                            ),
                            onClick: () => DeleteExpense(data.user_id, data.idx, isApproval),
                        },
                        ] : 
                        !props.isDisabled ? [{
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                    <GrayButton variant="outlined" size="medium">削除</GrayButton>
                                </div>
                            ),
                            onClick: () => DeleteExpense(data.user_id, data.idx, isApproval),
                        }] : [{
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                                    <GrayButton variant="outlined" size="medium" disabled>編集不可</GrayButton>
                                </div>
                            ),
                            onClick: () => {},
                        }]
                    }
                    
                    actionButtonMinWidth={90}
                    height={130}
                    // hideDotsButton={true}
                    >
                    <div style={{ display: 'flex', width: '100%'}}>
                        <div style={{ display: 'inline-flex', width: '5%', margin: '1em 0', padding: '2em 1em', justifyContent: 'flex-start'}}>
                            <div className={styles.DidRest_component}>
                                <span>{props.index+1}</span>
                            </div>
                        </div>
                        <div style={{ display: 'inline-flex', width: '90%', flexDirection: 'column'}}>
                            <div style={{ display: 'inline-flex', width: '80%', margin: '1em 0', justifyContent: "space-between"}}>
                                <div>
                                    <span>{data.purchase_name}<small>{ !data.admin_ok_date ? '':'(承認済み)'}</small></span>
                                </div>
                                <div>
                                    <span>{data.amount.toLocaleString()}</span>
                                </div>
                            </div>
                            <div style={{ display: 'inline-flex', width: '80%', flexDirection: 'column'}}>
                                <TextField
                                    id="outlined-multiline-static"
                                    value={data.memo}
                                    label="詳細"
                                    multiline
                                    rows={2}
                                    inputProps={{style: {fontSize: '14px', padding: '0', width: '100%'}}} // font size of input text
                                    InputLabelProps={{style: {fontSize: '16px'}}} // font size of input label
                                    disabled
                                    // onChange={(e) => setMemo(e.target.value)}
                                />
                            </div>
                            <div style={{'font-size':'0.7em','display':'flex'}}>
                                <span>{formatStartDate(data.start_date,false)}</span><span style={{'margin-left':'10px'}}>{formatStartDate(data.start_date,true)}</span>
                            </div>
                        </div>
                    </div>    
                </SwipeToRevealActions>
                <ExpenseFormModal isOpen={isModalOpen} onAlertModalClose={handleClose} users={props.users} isRefresh={props.isRefresh} setIsRefresh={props.setIsRefresh} data={data}  paymentDate={paymentDate} />
            </div>
        </>
    );
};

export default MyExpenseList;
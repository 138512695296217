/* eslint-disable-next-line */
/* eslint-disable */
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard/dashboard';
import Logout from './pages/logout/Logout';
import Adminboard from './pages/adminboard/adminboard';
import Myboard from './pages/myboard/myboard';
import Mypage from './pages/mypage/mypage';
import Join from './pages/join/join';
import Expense from './pages/expense/expense';
import Document from './pages/document/document';
import Expenseboard from './pages/expenseboard/expenseboard';
import { LoadingProvider } from './LoadingContext';
import Holidayboard from './pages/holidayboard/holidayboard';
import Test from './pages/test/test';
import NotFound from './NotFound';

const App = () => {

  const navigate = useNavigate();

  useEffect(() => {

    const isAuthenticated = localStorage.getItem('users');
    const users = JSON.parse(isAuthenticated);

    if (!isAuthenticated) {

      if(window.location.pathname !== '/') {
        navigate('/');
      }

    } else {

      if(window.location.pathname === '/adminboard' && users.admin_type === 2 || users.admin_type === '2') {
        navigate('/dashboard');
      }
      if(window.location.pathname.includes('/join') && users.admin_type === 2 || users.admin_type === '2') {
        navigate('/');
      }

      const loginTime = localStorage.getItem('loginTime');

      if (loginTime) {

        const currentTime = new Date().getTime();
        const timeDifference = currentTime - loginTime;

        if (timeDifference > 12 * 60 * 60 * 1000) { // 12時間後ログアウト
          localStorage.clear();
          navigate('/');
        }

      }

    }
    
    const intervalId = setInterval(() => {

      const loginTime = localStorage.getItem('loginTime');
      
      if (loginTime) {

        const currentTime = new Date().getTime();
        const timeDifference = currentTime - loginTime;
        
        if (timeDifference > 12 * 60 * 60 * 1000) { // 12時間後ログアウト
          localStorage.clear();
          navigate('/');
        }

      }
    }, 60000);

    return () => clearInterval(intervalId);

}, []);

  return (
      <div className="app">
        <LoadingProvider>
          <Routes> 
            <Route exact path="/" element={<Login/>} />
            <Route path="/logout" element={<Logout/>} />
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/adminboard" element={<Adminboard />} />
            <Route path="/myboard" element={<Myboard />} />
            <Route path="/mypage" element={<Mypage />} />
            <Route path="/join" element={<Join />} />
            <Route path="/expense" element={<Expense />} />
            <Route path="/document" element={<Document />} />
            <Route path="/expenseboard" element={<Expenseboard />} />
            <Route path="/holidayboard" element={<Holidayboard />} />
            <Route path="/test" element={<Test />} />
            <Route component={NotFound} />
          </Routes>
        </LoadingProvider>
      </div>
  );
};

export default App;
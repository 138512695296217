import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectBox = (props) => {

  const [status, setStatus] = React.useState('');

  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  return (
    <Box sx={{ minWidth: 120 }}  >
      <FormControl fullWidth size="small">
        <Select
          defaultValue={1}
          value={status}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={(e) => props.setStatus(e.target.value)}
        >
          <MenuItem value={1}>祝日</MenuItem>
          <MenuItem value={2}>お知らせ</MenuItem>
          <MenuItem value={3}>期限</MenuItem>
          <MenuItem value={4}>その他</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectBox;
import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import styles from '../../pages/document/document.module.scss';
import DocumentFormModal from "./DocumentFormModal";
// import { purple, blue } from '@mui/material/colors';
import AxiosConfig from "../../AxiosConfig";



const MyDocumentList = (props) => {

    const GreenButton = styled(Button)(({ theme }) => ({
        color: '#fff',
        backgroundColor: '#00E6A7',
        '&:hover': {
          backgroundColor: '#00CC94',
        },
    }));
    const GrayButton = styled(Button)(({ theme }) => ({
        color: '#fff',
        backgroundColor: '#d8d8d8',
        '&:hover': {
            backgroundColor: '#bfbfbf',
        },
    }));

    
    const [data, setData] = useState(props.data);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [users, setUsers] = useState('');

    useEffect(() => {
        setData(props.data);
        setUsers(props.users);
    }, [props.isRefresh, props.data]);

    
    const DocumentClickOpen = () => {
        setIsModalOpen(true);
    };
    const handleClose = () => {
        setIsModalOpen(false);
        props.setIsRefresh(false);
    };



    const DeleteDocument = async(param1) => {

        // const users = props.users;
        try {
            
            const data = {
                id: param1,
            };
            
            // console.log(data);
            const response = await AxiosConfig.post('/api/delete_document', data);
            if (response.data.res.code === 'success') {

                // alert('削除しました!');
                props.setIsRefresh(true);

            } else {

                alert(response.data.res.msg);

            }
            
        } catch (error) {

            console.log(error);
            alert('削除失敗しました');

        }
    };



    
    return (
        <>
            <div className={`list_menu_items`} key={props.date} style={{ borderBottom: '1.5px solid #ddd', padding: '0 0 0.5em 0' }}>
                <SwipeToRevealActions
                    actionButtons={[
                        {
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                    <GreenButton variant="outlined" size="medium">編集</GreenButton>
                                </div>
                            ),
                            onClick: () => DocumentClickOpen(),
                        },
                        {
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                    <GrayButton variant="outlined" size="medium">削除</GrayButton>
                                </div>
                            ),
                            onClick: () => DeleteDocument(data.id),
                        },
                    ]}
                    actionButtonMinWidth={90}
                    height={120}
                    hideDotsButton={users.admin_type === '1' || users.admin_type === 1 ? false : true}
                    >
                    <div style={{ display: 'flex', width: '100%'}}>
                        <div style={{ display: 'inline-flex', width: '5%', margin: '1em 0', padding: '2em 1em', justifyContent: 'flex-start'}}>
                            <div className={styles.DidRest_component}>
                                <span>{props.index+1}</span>
                            </div>
                        </div>
                        <div style={{ display: 'inline-flex', width: '90%', flexDirection: 'column'}}>
                            <div style={{ display: 'inline-flex', width: users.admin_type === '1' || users.admin_type === 1 ? '70%' : '90%', margin: '.6em 0', justifyContent: "space-between"}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <span>{data.title}</span>
                                </div>
                                <div>
                                    {
                                        window.location.hostname === "localhost" ? 
                                        <a href={`http://localhost:5173/api/download?file=${data.filename}`}>
                                            <img src="https://596576bca7dcb637.main.jp/tool/image/download_image_icon.png" alt="loading" style={{ width: '35px' }} />
                                            {/* {data.filename} */}
                                        </a>
                                        : 
                                        <a href={`https://intraback.r2corp.co.jp/api/download?file=${data.filename}`}>
                                            <img src="https://596576bca7dcb637.main.jp/tool/image/download_image_icon.png" alt="loading" style={{ width: '35px' }} />
                                            {/* {data.filename} */}
                                        </a>
                                    }
                                </div>
                            </div>
                            <div style={{ display: 'inline-flex', width: users.admin_type === '1' || users.admin_type === 1 ? '70%' : '90%', flexDirection: 'column'}}>
                                <TextField
                                    id="outlined-multiline-static"
                                    value={data.details}
                                    label="詳細"
                                    multiline
                                    rows={2}
                                    inputProps={{style: {fontSize: '14px', padding: '0', width: '100%'}}} // font size of input text
                                    InputLabelProps={{style: {fontSize: '16px'}}} // font size of input label
                                    disabled
                                    // onChange={(e) => setMemo(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>    
                </SwipeToRevealActions>
                <DocumentFormModal isOpen={isModalOpen} onAlertModalClose={handleClose} users={props.users} isRefresh={props.isRefresh} setIsRefresh={props.setIsRefresh} data={data} />
            </div>
        </>
    );
};

export default MyDocumentList;
import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import styles from '../../pages/holidayboard/holidayboard.module.scss';
import HolidayFormModal from "./HolidayFormModal";
// import { purple, blue } from '@mui/material/colors';
import AxiosConfig from "../../AxiosConfig";


function getDayOfWeek(yyyyMMdd){
	
    let dayOfWeek = new Date(yyyyMMdd).getDay(); 
    
    //0:日, 1:月, 2:火, 3:水, 4:木, 5:金, 6:土
    let week = '';

    if(dayOfWeek === 0) {
        week = '日'
    } else if(dayOfWeek === 1) {
        week = '月'
    } else if(dayOfWeek === 2) {
        week = '火'
    } else if(dayOfWeek === 3) {
        week = '水'
    } else if(dayOfWeek === 4) {
        week = '木'
    } else if(dayOfWeek === 5) {
        week = '金'
    } else if(dayOfWeek === 6) {
        week = '土'
    } else {
        week = ''
    }

    return week;
}
  

function getNameOfStatus(st){
	
    let status = '';

    if(st === 1) {
        status = '祝日'
    } else if(st === 2) {
        status = 'お知らせ'
    } else if(st === 3) {
        status = '期限'
    } else if(st === 4) {
        status = 'その他'
    } else {
        status = ''
    }

    return status;
}
  
  


const HolidayList = (props) => {

    const GreenButton = styled(Button)(({ theme }) => ({
        color: '#fff',
        backgroundColor: '#00E6A7',
        '&:hover': {
          backgroundColor: '#00CC94',
        },
    }));
    const GrayButton = styled(Button)(({ theme }) => ({
        color: '#fff',
        backgroundColor: '#d8d8d8',
        '&:hover': {
            backgroundColor: '#bfbfbf',
        },
    }));

    
    const [data, setData] = useState(props.data);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setData(props.data);
    }, [props.isRefresh, props.data]);

    
    const HolidayClickOpen = () => {
        setIsModalOpen(true);
    };
    const handleClose = () => {
        setIsModalOpen(false);
        props.setIsRefresh(false);
    };


    const DeleteHoliday = async(param1) => {

        if(window.confirm("本当に削除しますか？")) {

            try {
                
                const data = {
                    'holiday_date' : param1,
                };
                
                // console.log(data);
    
                const response = await AxiosConfig.post('/api/delete_holiday', data);
                if (response.data.res.code === 'success') {
    
                    // alert('削除しました!');
                    props.setIsRefresh(true);
    
                } else {
    
                    alert(response.data.res.msg);
    
                }
                
            } catch (error) {
    
                console.log(error);
                alert('削除失敗しました');
    
            }

        }

    };



    
    return (
        <>
            <div className={`list_menu_items`} key={props.date} style={{ borderBottom: '1.5px solid #ddd', padding: '0 0 0.5em 0' }}>
                <SwipeToRevealActions
                    actionButtons={[
                        {
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                    <GreenButton variant="outlined" size="medium">編集</GreenButton>
                                </div>
                            ),
                            onClick: () => HolidayClickOpen(),
                        },
                        {
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.1em' }}>
                                    <GrayButton variant="outlined" size="medium">削除</GrayButton>
                                </div>
                            ),
                            onClick: () => DeleteHoliday(data.holiday_date),
                        },
                    ]}
                    actionButtonMinWidth={90}
                    height={120}
                    // hideDotsButton={true}
                    >
                    <div style={{ display: 'flex', width: '100%'}}>
                        <div style={{ display: 'inline-flex', width: '5%', margin: '1em 0', padding: '2em 1em', justifyContent: 'flex-start'}}>
                            <div className={styles.DidRest_component}>
                                <span>{props.index+1}</span>
                            </div>
                        </div>
                        <div style={{ display: 'inline-flex', width: '90%', flexDirection: 'column'}}>
                            <div style={{ display: 'inline-flex', width: '70%', margin: '1em 0', justifyContent: "space-between"}}>
                                <div>
                                    <span>{data.holiday_date}({getDayOfWeek(data.holiday_date)})</span>
                                </div>
                                <div>
                                    <span>{getNameOfStatus(data.status)}</span>
                                </div>
                            </div>
                            <div style={{ display: 'inline-flex', width: '70%', flexDirection: 'column'}}>
                                <TextField
                                    id="outlined-multiline-static"
                                    value={data.memo}
                                    label="詳細"
                                    multiline
                                    rows={2}
                                    inputProps={{style: {fontSize: '14px', padding: '0', width: '100%'}}} // font size of input text
                                    InputLabelProps={{style: {fontSize: '16px'}}} // font size of input label
                                    disabled
                                    // onChange={(e) => setMemo(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>    
                </SwipeToRevealActions>
                <HolidayFormModal isOpen={isModalOpen} onAlertModalClose={handleClose} users={props.users} isRefresh={props.isRefresh} setIsRefresh={props.setIsRefresh} data={data} />
            </div>
        </>
    );
};

export default HolidayList;
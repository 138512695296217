import React, {useEffect, useState} from 'react';
import '../dashboard/css/css.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function formatTime(timeString) {
  if (!timeString) return "";

  const timeParts = timeString.split(':');
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  const currentDate = new Date();
  const dateWithTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hours, minutes);

  return dateWithTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
}

const DidRest = (props) => {

  const [works, setWorks] = useState([]);
  const [memo, setMemo] = useState();
  const [restCode, setRestCode] = useState(false);

  useEffect(() => {
    setWorks(props.works);
    setMemo(props.memo);
    if (props.works && props.works.hasOwnProperty('rest_code')) {
      setRestCode(props.works.rest_code);
    }
  }, [props.works, restCode, props.memo]);
  
  return (
    <>

      <div className='DidRest_component'>
          <div>
            <Button variant="outlined" size="medium">出勤</Button>
          </div>
      </div>
      <div className='DidRest_component'>
          <div>
            <Button variant="contained" size="medium" color="error" onClick={ props.isSubmit === true ? () => alert('提出済みです。') : props.HolidayClickOpen }>休み</Button>
          </div>
          <div>
            <span style={{ fontSize: '14px', color: '#f00' }}>{restCode === 11 ? '有給' : restCode === 12 ? '代休' : restCode === 13 ? '欠勤' : restCode === 14 ? '特休' : ''}</span>
          </div>
      </div>
      <div className='DidRest_component'>
          <div>
            <TextField
                id="outlined-multiline-static"
                value={ memo  ? memo : (props.works && props.works.memo) ? props.works.memo : ''}
                label="備考"
                multiline
                rows={2}
                inputProps={{style: {fontSize: '12px', padding: '0', width: '100%'}}} // font size of input text
                InputLabelProps={{style: {fontSize: '12px'}}} // font size of input label
                disabled
                // onChange={(e) => setMemo(e.target.value)}
            />                                                        
          </div>
      </div>

    </>
  );
};

export default DidRest;
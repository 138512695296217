import React, { useState, useRef, useEffect, useContext } from 'react';
import styles from './holidayboard.module.scss';
import CustomFlatpickr from '../../components/dashboard/CustomFlatpickr';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { common } from '@mui/material/colors';
import SideMenuTab from '../../components/SideMenuTab';
import AxiosConfig from '../../AxiosConfig';
import { LoadingContext } from '../../LoadingContext';
import { useLocation } from 'react-router-dom';
import HolidayList from '../../components/holidayboard/HolidayList';
import HolidayFormModal from '../../components/holidayboard/HolidayFormModal';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

const Holidayboard = () => {

  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const date = new Date();
  const initialYear = date.getFullYear();
  const initialMonth = (date.getMonth() + 1).toString().padStart(2, '0');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const paramsyear = queryParams.get('year');
  const paramsmonth = queryParams.get('month');
  const paramsuserid = queryParams.get('id');
  const [year, setYear] = useState(paramsyear ? paramsyear : initialYear);
  const [month, setMonth] = useState(paramsmonth ? paramsmonth : initialMonth);
  const [userid, setUserid] = useState(paramsuserid ? paramsuserid : '');
  const [iModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isAttendanceVisible, setIsAttendanceVisible] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [start, setStart] = useState();
  const [isRefresh, setIsRefresh] = useState(false);


  const handleDateChange = (selectedDate) => {
    setStart(selectedDate);
    setIsAttendanceVisible(!isAttendanceVisible);
  };
  const getYear = (year) => {
    setYear(year);
  }
  const getMonth = (month) => {
    setMonth(month);
  }

  const ModalClickOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = (text) => {
    setIsModalOpen(false);
  };

  const flatpickrRef = useRef();

  const handleClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.focus();
      flatpickrRef.current.click(); 
    }
  };
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  }));


  useEffect(() => {

    if(localStorage.users) {
      setUsers(JSON.parse(localStorage.users));
    }
    setIsLoading(true);

    const data = {
      'year': year,
      'month': month,
    };
    
    AxiosConfig.get('/api/get_holiday_api', { params: data })
    .then((res) => {

      // console.log(res);
      setHolidays(res.data.res.data);

    }).catch(error => {

        alert('エラー');
        console.log(error);

    }).finally(() => {

      setIsLoading(false);
      setIsRefresh(false);

    }); 
      
  }, [year, month, isRefresh]);
  



  return (
    <>
      { isLoading && (
        <div className="loading-container" style={{ zIndex:'999'}}>
          {/* <img src="https://596576bca7dcb637.main.jp/tool/image/loading_bar.gif" alt="loading" /> */}
          <img src="https://596576bca7dcb637.main.jp/tool/image/loading-bar.gif" alt="loading" style={{ width: '50px' }} />
        </div>
      )}
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.sub_header}>
            <div className={styles.header_datepicker}>
              <CustomFlatpickr onDateChange={handleDateChange} year={year} month={month} getYear={getYear} getMonth={getMonth} ref={flatpickrRef}  />
              <ArrowDownwardRoundedIcon sx={{ color : common.white }} fontSize="large" onClick={handleClick} />
            </div>   
            <div className={styles.header_button}>
              <ColorButton variant="contained" onClick={ModalClickOpen} style={{ marginRight: '10px',zIndex: '999' }}>祝日入力</ColorButton>
              {/* <button className={styles.edit}>戻る</button> */}
              <SideMenuTab usertype={users.admin_type} />
            </div>   
          </div>  
          <nav className={styles.tabs}>
            <button
              className={styles.active}
              >
              祝日履歴
            </button>
            {/* <button
              className={styles.apply}
              onClick={ModalClickOpen}
            >
              祝日入力
            </button> */}
          </nav>
        </header>
        
        <main className={styles.main_content}>
          <section className={`${styles.section} ${styles.section_left}`}>
            <ul>
              {
                holidays.map((key, index) => {
                  return (
                    <HolidayList key={`${key}-${index}`} index={index} data={key} users={users} isRefresh={isRefresh} setIsRefresh={setIsRefresh}  />
                  );
                })
              }
            </ul>
          </section>
        </main>
          <HolidayFormModal isOpen={iModalOpen} onAlertModalClose={handleClose} users={users} setIsRefresh={setIsRefresh} />
        <footer className={styles.footer}>
        </footer>
      </div>
    </>
  );
};

export default Holidayboard;
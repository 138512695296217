import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const LoginInputForm = (props) => {

  const handleIdChange = (event) => {
    const id = event.target.value;
    props.handleIdChange(id);
  };
  const handlePassChange = (event) => {
    const pass = event.target.value;
    props.handlePassChange(pass);
  };

  return (
    <Box
        sx={{
            maxWidth: '100%',
        }}
    >
      <div>
        <TextField
          id="outlined-id-input"
          label="ID"
          fullWidth
          size='small'
          margin="normal"
          onChange={handleIdChange}
        />
        <TextField
          id="outlined-password-input"
          label="パスワード"
          type="password"
          autoComplete="current-password"
          fullWidth
          size='small'
          margin="normal"
          onChange={handlePassChange}
        />
      </div>
    </Box>
  );
}

export default LoginInputForm;
import React from 'react'
import FullCalendar from '@fullcalendar/react'
import multiMonthPlugin from '@fullcalendar/multimonth'
import jaLocale from '@fullcalendar/core/locales/ja';

export default class Test extends React.Component {
  render() {
    return (
      <FullCalendar
        plugins={[ multiMonthPlugin ]}
        initialView="multiMonthYear"
        locale={jaLocale}
        multiMonthMaxColumns={2}
        events={[
            { title: '祝日', date: '2023-04-01' },
            { title: '祝日祝日祝日祝日', date: '2023-04-02' }
        ]}
      />
    )
  }
}
import React, { useState, useEffect,  useRef, useContext } from 'react';
import styles from './adminboard.module.scss'
import CustomFlatpickr from '../../components/dashboard/CustomFlatpickr';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { common } from '@mui/material/colors';
import BasicTable from '../../components/adminboard/BasicTable';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AxiosConfig from '../../AxiosConfig';
import './adminboard.scss';
import { LoadingContext } from '../../LoadingContext';
import SideMenuTab from '../../components/SideMenuTab';

const Adminboard = () => {

  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const date = new Date();
  const initialYear = date.getFullYear();
  const initialMonth = date.getMonth() + 1;
  const [year, setYear] = useState(initialYear);
  const [month, setMonth] = useState(initialMonth);
   
  const [users, setUsers] = useState([]);
  const [summary, setSummary] = useState([]);
  const [inMemo, setInMemo] = useState('');
  const [outMemo, setOutMemo] = useState('');
  const [adminIn, setAdminIn] = useState();
  const [adminOut, setAdminOut] = useState();
  const [stat, setStat] = useState(false);
  const [isSubmit, setIsSubmit] = useState('');

  const [user, setUser] = useState([]);

  const getYear = (year) => {
    setYear(year);
  }
  const getMonth = (month) => {
    setMonth(month);
  }
  const [start, setStart] = useState();

  const handleDateChange = (selectedDate) => {
    setStart(selectedDate);
  };

	useEffect(() => {

    setIsLoading(true);

    const data = {
      'year': year,
      'month': month,
    };

    let owner;
    if(localStorage.users) {
      setUser(JSON.parse(localStorage.users));
      owner = JSON.parse(localStorage.users);
    }

    setYear(year);
    setMonth(month);
    // console.log('data', data);
    AxiosConfig.get('/api/getUsers', { params: data })
    .then((res) => {
      // console.log(res);
      setUsers(res.data.res.users);
      setSummary(res.data.res.summary);
      if(res.data.res.admin_info[0] && res.data.res.admin_info[1]) {
        if(res.data.res.admin_info[0].type) {
          if(res.data.res.admin_info[0].type === 1) {
            setInMemo(res.data.res.admin_info[0].admin_memo);
          }
        }
        if(res.data.res.admin_info[1].type) {
          if(res.data.res.admin_info[1].type === 2) {
            setOutMemo(res.data.res.admin_info[1].admin_memo);
          }
        }
      }
    }).catch(error => {
        alert('エラー');
        console.log(error);
    }).finally(() => setIsLoading(false));
  }, [year, month, stat, setIsSubmit, setIsLoading])
  
  const flatpickrRef = useRef();

  const handleClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.focus();
      flatpickrRef.current.click(); 
    }
  };


  const in_memo_save = async() => {

    const user = JSON.parse(localStorage.getItem("users"));

    if (window.confirm("登録しますか？")) {
      try {
        const data = {
            type : 1,
            date : year + '-' + month,
            admin_memo : inMemo,
            create_user_name : user.family_name,
        };
        // console.log(data);

        const response = await AxiosConfig.post('/api/add_admininfo', data);

        if (response.status === 200) {
            alert('登録完了しました!');
        } else {
            alert('登録失敗しました。');
        }
      } catch (error) {
          console.log(error);
          alert('登録中にエラーが発生しました。');
      }
    } 
  };

  const out_memo_save = async() => {
    const user = JSON.parse(localStorage.getItem("users"));

    if (window.confirm("登録しますか？")) {
      try {
        const data = {
            type : 2,
            date : year + '-' + month,
            admin_memo : outMemo,
            create_user_name : user.family_name,
        };
        // console.log(data);

        const response = await AxiosConfig.post('/api/add_admininfo', data);

        if (response.status === 200) {
            alert('登録完了しました!');
        } else {
            alert('登録失敗しました。');
        }
      } catch (error) {
          console.log(error);
          alert('登録中にエラーが発生しました。');
      }
    } 
  };



  return (
    <>
      { isLoading && (
        <div className="loading-container" style={{ zIndex:'999'}}>
          {/* <img src="https://596576bca7dcb637.main.jp/tool/image/loading_bar.gif" alt="loading" /> */}
          <img src="https://596576bca7dcb637.main.jp/tool/image/loading-bar.gif" alt="loading" style={{ width: '50px' }} />
        </div>
      )}
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.header_datepicker}>
            <CustomFlatpickr onDateChange={handleDateChange} getYear={getYear} getMonth={getMonth} ref={flatpickrRef}  />
            <ArrowDownwardRoundedIcon sx={{ color : common.white }} fontSize="large" onClick={handleClick} />
          </div>
          <div className={styles.header_title}>
            <h2>社員勤怠状況一覧</h2>
          </div>
          <div className={styles.header_button}>
              {/* <button className={styles.edit}>戻る</button> */}
              <SideMenuTab usertype={user.admin_type} />
              {/* <SideMenuTab /> */}
          </div>   
        </header>
        <main className={styles.main_content}>
          <section className={styles.section + ' ' + styles.section_left}>
            {/* <div className={styles.BasicTableDiv} style={{padding: '0em 1em 30em', display: 'flex', overflowX: 'auto', height: 'calc(85vh)' }}> */}
            <div className={styles.BasicTableDiv} style={{padding: '0em 1em 1em', display: 'flex', overflowX: 'auto', height: 'auto' }}>
              <BasicTable users={users} year={year} month={month} setStat={setStat} summary={summary} setIsSubmit={setIsSubmit} />
            </div>
          </section>
          <section className={styles.section + ' ' + styles.section_left} style={{ display: 'block', width: '100%', backgroundColor: '#f3f6fc' }}>
            <div style={{display: 'flex',    'box-shadow': '0 10px 20px rgba(0, 0, 0, 0.3)'}}>
              <div className={styles.adminFieldArea}>
                <TextField
                id="outlined-multiline-static"
                label="社内管理者メモ"
                multiline
                rows={4}
                style={{ width : '100%' , backgroundColor : 'white'}}
                value={inMemo}
                onChange={(event) => setInMemo(event.target.value)}
                />
                {/* <Button variant="contained" size="large" style={{width: '35%', margin: '10px 0' }}>社内管理者メモ保存</Button> */}
                <Button variant="contained" size="large" style={{width: '35%', margin: '10px 0' }} onClick={in_memo_save}>社内管理者メモ保存</Button>
              </div>
              <div className={styles.adminFieldArea}>
                <TextField
                  id="outlined-multiline-static"
                  label="社外管理者メモ"
                  value={outMemo}
                  multiline
                  rows={4}
                  style={{ width : '100%' , backgroundColor : 'white'}}
                  onChange={(e) => setOutMemo(e.target.value)}
                />
                {/* <Button variant="contained" size="large" style={{width: '35%', margin: '10px 0' }}>社外管理者メモ保存</Button> */}
                <Button variant="contained" size="large" style={{width: '35%', margin: '10px 0' }} onClick={out_memo_save}>社外管理者メモ保存</Button>
              </div>
            </div>
          </section>
        </main>
        <footer className={styles.footer}></footer>
      </div>
    </>
  );
};

export default Adminboard;


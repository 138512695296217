import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import AxiosConfig from '../../AxiosConfig';
import Link from '@mui/material/Link';
import dayjs from 'dayjs';
import AdminConfirmModal from './AdminConfirmModal';
import TextField from '@mui/material/TextField';


const myCallback = (run) => {
    return run();
};

const AdvancedTable = (props) => {

  // const [year, setYear] = useState(props.year);
  // const [month, setMonth] = useState(props.month);
  const [users, setUsers] = useState();
  const [isOpen, setIsOpen] = useState(false);


  useEffect(() => {
    // console.log(props.userExpense);
    if(localStorage.users) {
      setUsers(JSON.parse(localStorage.users));
    }
  }, [props.year, props.month, props.isRefresh]);

  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };


  const admin_expense_ok = async(idx, user_id) => {

    const data = {
      'idx': idx,
      'user_id' : user_id,
    }
    try {
      const response = await AxiosConfig.post('/api/expense_admin_ok', data);

      if (response.status === 200) {
        props.setIsRefresh(true);
      } else {
        alert('保存失敗しました。');
      }
    } catch (error) {
      console.log(error);
      alert('保存失敗しました。');
    }
  }

  const admin_expense_cancel = async(idx, user_id) => {

    const data = {
      'idx': idx,
      'user_id' : user_id,
    }
    try {
      const response = await AxiosConfig.post('/api/expense_admin_cancel', data);

      if (response.status === 200) {
        props.setIsRefresh(true);
      } else {
        alert('保存失敗しました。');
      }
    } catch (error) {
      console.log(error);
      alert('保存失敗しました。');
    }
  }

  let grandTotalAmount = 0;

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, border: '1px 0 solid #d8d8d8',padding: '7px 10px' }} aria-label="simple table"  className="expenseTable">
          <TableHead>
            <TableRow>
              <TableCell ColSpan="9" style={{textAlign: 'center', border: '1px solid #d8d8d8',padding: '7px 10px'}}>申請者</TableCell>
              <TableCell ColSpan="3" style={{textAlign: 'center', border: '1px solid #d8d8d8',padding: '7px 10px'}}>蒲池</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >名前</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >承認</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >申請日</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >購入日</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >利用開始日<br />利用終了日</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >購入物</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >金額</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >継続有無</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >参考</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >承認日</TableCell>
              <TableCell  align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >参考</TableCell>
            </TableRow>
            <TableRow><TableCell colspan="11" align="center" style={{ width: '8%', padding: '5px'}}></TableCell></TableRow>
          </TableHead>
          <TableBody>
            {
              props.userExpense.map((exp) => {
                // console.log(exp)
                grandTotalAmount += exp.totalAmount;
                return (
                <>
                  
                    <TableCell rowspan={exp.data.length+2} component="th" scope="row" align="center" style={{border: '1px solid #d8d8d8',padding: '7px 10px'}} >{exp.name}</TableCell>
                    {exp.data.map((dataItem, index) => {
                      return (
                      <>
                      {/* <TableRow> */}
                      <TableRow>
                        <TableCell align="center" style={{borderLeft: '1px solid #d8d8d8',borderRight: '1px solid #d8d8d8', width: '8%', padding: '7px 10px'}}>
                          {
                            dataItem.admin_ok_date === null ?  (
                              <Link onClick={() => admin_expense_ok(dataItem.idx, dataItem.user_id)} style={{backgroundColor: '#A4C2F4' , color: '#000', cursor: 'pointer'}}>承認する</Link>
                              // <Link onClick={() => handleClickOpen()} style={{backgroundColor: '#A4C2F4' , color: '#000', cursor: 'pointer'}}>承認する</Link>
                            ) : (
                              <Link onClick={() => admin_expense_cancel(dataItem.idx, dataItem.user_id)} style={{backgroundColor: '#FFE599' , color: '#000', cursor: 'pointer'}}>承認取り消し</Link>
                            )
                          }
                        </TableCell>
                        <TableCell  align="center" style={{borderRight: '1px solid #d8d8d8', width: '8%', padding: '7px 10px'}}>{dayjs(dataItem.create_on).format('YYYY-MM-DD')}</TableCell>
                        <TableCell  align="center" style={{borderRight: '1px solid #d8d8d8', width: '8%', padding: '7px 10px'}}>{dayjs(dataItem.purchase_date).format('YYYY-MM-DD')}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid #d8d8d8',padding: '7px 10px', width: '8%'}}>
                          {dataItem.start_date ? dayjs(dataItem.start_date).format('YYYY-MM-DD') : ''}<br />
                          {dataItem.start_date ? dayjs(dataItem.start_date).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD') : ''}
                        </TableCell>
                        <TableCell  align="center" style={{borderRight: '1px solid #d8d8d8', padding: '7px 10px'}}>{dataItem.purchase_name}</TableCell>
                        <TableCell  align="center" style={{borderRight: '1px solid #d8d8d8', padding: '7px 10px'}}>{dataItem.amount.toLocaleString()}</TableCell>
                        <TableCell  align="center" style={{borderRight: '1px solid #d8d8d8', padding: '7px 10px'}}>{dataItem.status === 3 ? 'O' : ''}</TableCell>
                        <TableCell  align="center" style={{borderRight: '1px solid #d8d8d8', width: '25%', padding: '7px 10px'}}>
                          <TextField
                            id="outlined-multiline-static"
                            value={dataItem.memo}
                            label={dataItem.memo !== '' ? '詳細':''}
                            multiline
                            rows={dataItem.memo && dataItem.memo.includes("\n") ? '2':'1'}
                            inputProps={{style: {fontSize: '14px', padding: '0', width: '100%'}}} // font size of input text
                            InputLabelProps={{shrink: true,style: {fontSize: '16px'}}} // font size of input label
                            disabled
                            style={{width: '100%'}}
                            // onChange={(e) => setMemo(e.target.value)}
                          />
                        </TableCell>
                        <TableCell  align="center" style={{borderRight: '1px solid #d8d8d8', padding: '7px 10px'}}>{dataItem.admin_ok_date ? dayjs(dataItem.admin_ok_date).format('YYYY-MM-DD') : ''}</TableCell>
                        <TableCell  align="center" style={{borderRight: '1px solid #d8d8d8', padding: '7px 10px'}}>{dataItem.admin_memo}</TableCell>
                      </TableRow>
                      
                      </>
                    )
                    })}
                    <TableRow>
                      <TableCell colspan="4" align="center" style={{ width: '8%', padding: '7px 10px'}}></TableCell>
                      <TableCell colspan="1" align="center" style={{width: '8%', padding: '7px 10px' , 'font-weight': 'bold'}}>小計</TableCell>
                      <TableCell colspan="1" align="center" style={{ width: '8%', padding: '7px 10px' , 'font-weight': 'bold'}}>{new Intl.NumberFormat('ja-JP').format(exp.totalAmount)}</TableCell>
                      <TableCell colspan="4" align="center" style={{borderRight: '1px solid #d8d8d8', width: '8%', padding: '7px 10px'}}></TableCell>
                    </TableRow>
                    <TableRow><TableCell colspan="11" align="center" style={{ width: '8%', padding: '5px'}}></TableCell></TableRow>

                </>
                )
              })
            }
            <TableRow>
              <TableCell align="center" style={{border: '1px solid #d8d8d8', padding: '7px 10px' , 'font-weight': 'bold'}}>合計</TableCell>
              <TableCell colspan="10" align="center" style={{borderRight: '1px solid #d8d8d8', padding: '7px 10px' , 'font-weight': 'bold'}}>{new Intl.NumberFormat('ja-JP').format(grandTotalAmount)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <AdminConfirmModal isOpen={isOpen} handleClose={handleClose} admin_expense_ok={admin_expense_ok} /> */}
    </>
  );
}

export default AdvancedTable;
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../pages/dashboard/dashboard.module.scss';
import TextField from '@mui/material/TextField';
// import Input from '@mui/material/Input';
import "./css/css.scss";
import AxiosConfig from '../../AxiosConfig';

const AttitudeButton = styled(Button)(({ theme }) => ({
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid #000' ,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, minWidth: '200px'}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


function calculateWorkHours(startTime, endTime, restTime) {
    
  if(!startTime || !endTime || !restTime) {
      return false;
  } 

  const [startHours, startMinutes, startSeconds] = startTime.split(":").map(String);
  const [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
  const [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
  
  let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
  let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
  let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

  const workTimeStart1 = start;
  workTimeStart1.setHours(startHours, startMinutes, startSeconds);
  
  const workTimeEnd2 = end;
  workTimeEnd2.setHours(endHours, endMinutes, endSeconds);

  const workTimeRest3 = rest;
  workTimeRest3.setHours(restHours, restMinutes, restSeconds);

  let totalWorkTime;
  let totalWorkTimeMin;
  if(endHours > startHours) {
      totalWorkTime = endHours - startHours - restHours;
  } else {
      totalWorkTime = 24 + ((endHours - startHours) - restHours);
      if(endHours === startHours) {
          totalWorkTime = 0;
          totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
      }
  }

  if(Number(startMinutes) === Number(endMinutes)) {
      totalWorkTimeMin = 0 - Number(restMinutes);
  } else {
      totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
  }

  if(totalWorkTimeMin > 60) { 
      totalWorkTime -= 1;
      totalWorkTimeMin %= 60;
      if(totalWorkTimeMin > 0) {
          totalWorkTimeMin = 60 - totalWorkTimeMin;
      }
  } else if (totalWorkTimeMin === 60) { // over 60min
      totalWorkTimeMin %= 60;
  } else {
      if(totalWorkTimeMin > 0) {
          totalWorkTimeMin %= 60;
      } else {
          if(totalWorkTimeMin < 0) {
              totalWorkTimeMin = 60 + totalWorkTimeMin;
              totalWorkTime -= 1;
              if(totalWorkTimeMin < 0) {
                  totalWorkTimeMin += 60;
                  totalWorkTimeMin %= 60;
                  totalWorkTime -= 1;
              }
              if(totalWorkTime < 0) {
                  totalWorkTime = 0;
                  totalWorkTimeMin = 0;
              }
          } else {
              totalWorkTimeMin %= 60;
          }
      }
      totalWorkTimeMin %= 60;
  }
  
  if(totalWorkTime <= 0 && totalWorkTimeMin <= 0) { //時間確認必要
      return '00:00'; 
  } 
  
  const hours = Math.floor(totalWorkTime).toString().padStart(2, '0');
  const minutes = Math.floor(totalWorkTimeMin).toString().padStart(2, '0');

  // if(hours >= 12) {
  //     calculateNightWorkHours(startTime, endTime, restTime);
  // }

  return `${hours}:${minutes}`;

}



function calculateTimeRest() {

  const totalWorkTime = 0; 
  const totalWorkTimeMin = 0; 

  const hours = Math.floor(totalWorkTime).toString().padStart(2, '0');
  const minutes = Math.floor(totalWorkTimeMin).toString().padStart(2, '0');

  return `${hours}:${minutes}`;

}

const AttiFormModal = (props) => {


    const [late_Button, setLateButton] = useState(false);
    const [early_Button, setEarlyButton] = useState(false);
    const [morning_Button, setMorningButton] = useState(false);
    const [afternoon_Button, setAfternoonButton] = useState(false);

    const activeBtn_late = (button) => {
      if(button === late_Button) {
        setLateButton(false);
      } else if(morning_Button === 'morning' || morning_Button === true) {
        alert('遅刻と午前休は一緒に選択できません。');
        setLateButton(false);
      } else {
        setLateButton(button);
      }
    }
    const activeBtn_early = (button) => {
      if(button === early_Button) {
        setEarlyButton(false);
      }  else if(afternoon_Button === 'afternoon' || afternoon_Button === true) {
        alert('早退と午後休は一緒に選択できません。');
        setEarlyButton(false);
      } else {
        setEarlyButton(button);
      }
    }
    const activeBtn_morning = (button) => {
      if(button === morning_Button) {
        setMorningButton(false);
      } else if(late_Button === 'late' || late_Button === true) {
        alert('遅刻と午前休は一緒に選択できません。');
        setMorningButton(false);
      } else if(afternoon_Button === 'afternoon' || afternoon_Button === true) {
        alert('午前休と午後休は一緒に選択できません。');
        setMorningButton(false);
      } else {
        setMorningButton(button);
      }
    }
    const activeBtn_afternoon = (button) => {
      if(button === afternoon_Button) {
        setAfternoonButton(false);
      } else if(early_Button === 'early' || early_Button === true) {
        alert('早退と午後休は一緒に選択できません。');
        setAfternoonButton(false);
      } else if(morning_Button === 'morning' || morning_Button === true) {
        alert('午前休と午後休は一緒に選択できません。');
        setAfternoonButton(false);        
      } else {
        setAfternoonButton(button);
      }
    }


    const [year, month, day] = props.date.split("-");
    const formattedDate = `${month}/${day}`;  
    const [workTime, setWorkTime] = useState('00:00');
    const [timeIn, setTimeIn] = useState('');
    const [timeOut, setTimeOut] = useState('');
    const [timeRest, setTimeRest] = useState('');

    const handleClickClose = (param) => {
      if(param === 'work') {
        props.onAlertModalClose('work');
      } else {
        props.onAlertModalClose();

        setTimeout(() => {

          if (props.works && props.works.hasOwnProperty('is_late')) {
            setLateButton(props.works.is_late ? props.works.is_late : false);
            setEarlyButton(props.works.is_early ? props.works.is_early : false);
            setMorningButton(props.works.is_morning_rest ? props.works.is_morning_rest : false);
            setAfternoonButton(props.works.is_afternoon_rest ? props.works.is_afternoon_rest : false);
            setTimeIn(props.works.time_in ? props.works.time_in : <></>);
            setTimeOut(props.works.time_out ? props.works.time_out : <></>);
            setTimeRest(props.works.time_rest ? props.works.time_rest : <></>);
            console.log('2', props.works.time_in);
          }

          // setLateButton(props.works['is_late'] ? props.works['is_late'] : false);
          // setEarlyButton(props.works['is_early'] ? props.works['is_early'] : false);
          // setMorningButton(props.works['is_morning_rest'] ? props.works['is_morning_rest'] : false);
          // setAfternoonButton(props.works['is_afternoon_rest'] ? props.works['is_afternoon_rest'] : false);
        }, 300);

      }
    };


    useEffect(() => {

      if (props.works && props.works.hasOwnProperty('is_late')) {
        setLateButton(props.works.is_late ? props.works.is_late : false);
        setEarlyButton(props.works.is_early ? props.works.is_early : false);
        setMorningButton(props.works.is_morning_rest ? props.works.is_morning_rest : false);
        setAfternoonButton(props.works.is_afternoon_rest ? props.works.is_afternoon_rest : false);
        setTimeIn(props.works.time_in ? props.works.time_in : <></>);
        setTimeOut(props.works.time_out ? props.works.time_out : <></>);
        setTimeRest(props.works.time_rest ? props.works.time_rest : <></>);
        // console.log('1', props.works.time_in);
      }
      // setLateButton(props.works['is_late'] ? props.works['is_late'] : false);
      // setEarlyButton(props.works['is_early'] ? props.works['is_early'] : false);
      // setMorningButton(props.works['is_morning_rest'] ? props.works['is_morning_rest'] : false);
      // setAfternoonButton(props.works['is_afternoon_rest'] ? props.works['is_afternoon_rest'] : false);
  
    }, [props.works])
    

    const attitudeSave = async(selectedDate) => {

      const users = JSON.parse(localStorage.getItem("users"));

      let data;

      if(morning_Button || afternoon_Button) {

        data = {
          user_id : !props.userid ? users.id : props.userid,
          date: props.date,
          is_work : 'true',
          is_late : late_Button ? 'true' : 'false',
          is_early : early_Button ? 'true' : 'false',
          is_morning_rest : morning_Button ? 'true' : 'false',
          is_afternoon_rest : afternoon_Button ? 'true' : 'false',
          rest_code: morning_Button || afternoon_Button ? '11': '',
          update_user_name : users.family_name,
          memo : morning_Button ? '午前休' : afternoon_Button ? '午後休' : '',
          work_time: morning_Button && timeOut === '12:00:00' ? calculateWorkHours('13:00:00', users.template_out, '00:00:00') : morning_Button ? calculateWorkHours('13:00:00', timeOut, '00:00:00') : afternoon_Button && timeIn === '09:00:00' ? calculateWorkHours(timeIn, '12:00:00', '00:00:00') : calculateWorkHours(users.template_in, '12:00:00', '00:00:00'),
          timeIn: afternoon_Button ? users.template_in : morning_Button ? '13:00:00' : timeIn , // 1:午前休 2:午後休
          timeOut: morning_Button ? users.template_out : afternoon_Button ? '12:00:00' : timeOut , // 1:午前休 2:午後休
          timeRest: '00:00:00',
        };

      } else {

        data = {
          user_id : !props.userid ? users.id : props.userid,
          date: props.date,
          is_work : 'true',
          is_late : late_Button ? 'true' : 'false',
          is_early : early_Button ? 'true' : 'false',
          is_morning_rest : morning_Button ? 'true' : 'false',
          is_afternoon_rest : afternoon_Button ? 'true' : 'false',
          rest_code: morning_Button || afternoon_Button ? '11': '',
          update_user_name : users.family_name,
          memo : morning_Button ? '午前休' : afternoon_Button ? '午後休' : '',
        };

      }

      console.log('data', data);
      // console.log(props);
      // console.log('morning_Button', morning_Button);
      // console.log('afternoon_Button', afternoon_Button);
      // console.log('timeIn', timeIn);
      // console.log('timeOut', timeOut);
      try {

          const response = await AxiosConfig.post('/api/update_worktime', data);

          if (response.status === 200) {
              // alert('保存しました!');
              handleClickClose('work');
          } else {
              alert('保存失敗しました。');
          }
      } catch (error) {
          console.log(error);
          alert('保存失敗しました。');
      }
      
    };


    const calculateTime = () => {
      // setWorkTime(calculateWorkHours(startTime, endTime, restTime));
    }



    return (
        <div>
            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickClose}>
                    {formattedDate}({props.weekday})勤怠設定
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div>
                        <div>
                            <div className="line_title_div">
                                <AttitudeButton variant="outlined" size="medium"  onClick={() => activeBtn_late("late")} className={late_Button === "late" || late_Button === true ? "active" : ""}>遅刻</AttitudeButton>
                            </div>
                            <div className="line_title_div">
                                <AttitudeButton variant="outlined" size="medium" onClick={() => activeBtn_early("early")} className={early_Button === "early" || early_Button === true ? "active" : ""}>早退</AttitudeButton>
                            </div>
                        </div>
                        <div>
                            <div className="line_title_div">
                                <AttitudeButton variant="outlined" size="medium" onClick={() => activeBtn_morning("morning")} className={morning_Button === "morning" || morning_Button === true ? "active" : ""}>午前休</AttitudeButton>
                            </div>
                            <div className="line_title_div">
                                <AttitudeButton variant="outlined" size="medium" onClick={() => activeBtn_afternoon("afternoon")} className={afternoon_Button === "afternoon" || afternoon_Button === true ? "active" : ""}>午後休</AttitudeButton>
                            </div>
                        </div>
                    </div>
                    <div className="submitBtnArea" style={{ display: 'flex', 'justifyContent': 'center' }}>
                        <Button variant="outlined" onClick={attitudeSave}>登録</Button>
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

// export default AlertModal;

export default React.memo(AttiFormModal);
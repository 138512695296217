/* eslint-disable no-restricted-globals */
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AxiosConfig from '../../AxiosConfig';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import JoinDatePick from './JoinDatePick';
import dayjs from 'dayjs';
import JoinStatusCheckbox from './JoinStatusCheckbox';

const JoinInputForm = (props) => {


  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [visibleFlag, setVisibleFlag] = useState(true);
  const [memo, setMemo] = useState('');
  const [joinDate, setJoinDate] = useState(dayjs().format('YYYY-MM-DD'));
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');


  useEffect(() => {


    if(id > 0) {

      setIsLoading(true);
  
      const data = {
        'id': id,
      }; 
      // let owner;
      // if(localStorage.users) {
      //   setUser(JSON.parse(localStorage.users));
      //   owner = JSON.parse(localStorage.users);
      // }
  
      // console.log('data', data);
      
      AxiosConfig.get('/api/getUserinfo', { params: data })
      .then((res) => {
        // console.log(dayjs(res.data.res.users.join_date).format('YYYY-MM-DD'));
        setLoginId(res.data.res.users.login_id);
        setFamilyName(res.data.res.users.family_name);
        setFirstName(res.data.res.users.first_name);
        setJoinDate(dayjs(res.data.res.users.join_date).format('YYYY-MM-DD'));
        setVisibleFlag(res.data.res.users.visible_flag);
        setDeleteFlag(res.data.res.users.delete_flag);
        // setUsers(res.data.res.users);
      }).catch(error => {
          alert('エラー');
          console.log(error);
      }).finally(() => setIsLoading(false));
      
    }
  }, [setIsLoading])


  const renderLoadingImage = () => {
    return isLoading ? (
      <div className="loading-container">
        {/* <img src="https://596576bca7dcb637.main.jp/tool/image/loading_bar.gif" alt="Loading" /> */}
        <img src="https://596576bca7dcb637.main.jp/tool/image/loading-bar.gif" alt="loading" style={{ width: '50px' }} />
      </div>
    ) : null;
  };

  const getJoinUser = async() => {


      const data = {
        'login_id' : loginId,
        'login_password' : password,
        'family_name': familyName,
        'first_name': firstName,
        'join_date' : joinDate,
        'memo' : memo,
        'delete_flag' : deleteFlag,
        'visible_flag' : visibleFlag,
      }

      // const url = id > 0 ? '/api/updateUserInfo' : '/api/joinproc';
      
      if(!loginId || !password || !password2 || !familyName || !firstName) {
        alert('登録情報を入力してください。');
        return false;
      } else if(password !== password2) {
        alert('パスワードと確認パスワードは一致していません。。');
        return false;
      } 
      // else if(/\s/.test(password)) {
      //   alert('スペースは入力できません。');
      //   return false;
      // } else if(password.length < 8) {
      //   alert('パスワードは8文字以上入力してください。');
      //   return false;
      // } else if(!/\d/.test(password)) {
      //   alert('パスワードは数字を含めてください。');
      // } else if(!/[a-z]/.test(password)) {
      //   alert('パスワードは英小文字を含めてください。');
      // } else if(!/[A-Z]/.test(password)) {
      //   alert('パスワードは英大文字を含めてください。');
      // } 
      else {
        // console.log(data);
        setIsLoading(true);
        AxiosConfig.get('/sanctum/csrf-cookie').then(response => {
          AxiosConfig.post('/api/joinproc', data)
          .then((res) => {
            // console.log(res);
            if(res.data.res.code  === 'success') {
              alert(res.data.res.msg);
              navigate('/');
            } else {
              alert(res.data.res.msg);
            }
          }).catch(error => {
              alert('登録失敗しました！');
              console.log(error);
          }).finally(() => {
            setIsLoading(false);
          });
        });
      }
  }
  
  const updateUserInfo = async() => {

      const data = {
        'id': id,
        'login_id' : loginId,
        'family_name': familyName,
        'first_name': firstName,
        'join_date' : joinDate,
        'memo' : memo,
        'delete_flag' : deleteFlag,
        'visible_flag' : visibleFlag,
      }

      console.log(data);
      setIsLoading(true);
      AxiosConfig.get('/sanctum/csrf-cookie').then(response => {
        AxiosConfig.post('/api/updateUserInfo', data)
        .then((res) => {
          // console.log(res);
          if(res.data.res.code  === 'success') {
            alert(res.data.res.msg);
          } else {
            alert(res.data.res.msg);
          }
        }).catch(error => {
            alert('登録失敗しました！');
            console.log(error);
        }).finally(() => {
          setIsLoading(false);
        });
      });
  }

  
  const change_pass = async() => {

    const data = {
      'id' : id,
      'login_password' : password,
    }
    
    // console.log(data);
    if(!password || !password2) {
      alert('パスワードを入力してください。');
      return false;
    } else if(password !== password2) {
      alert('パスワードと確認パスワードは一致していません。');
      return false;
    } 
    else {
      // console.log(data);
      setIsLoading(true);
      AxiosConfig.get('/sanctum/csrf-cookie').then(response => {
        AxiosConfig.post('/api/change_pass', data)
        .then((res) => {
          // console.log(res);
          if(res.data.res.code  === 'success') {
            alert(res.data.res.msg);
          } else {
            alert(res.data.res.msg);
          }
        }).catch(error => {
            alert('変更失敗');
            console.log(error);
        }).finally(() => {
          setPassword('');
          setPassword2('');
          // window.location.reload();
          setIsLoading(false);
        });
      });
    }
  }


  return (
    <>
      <Box sx={{ maxWidth: '100%', }}>
        <div>
          <TextField
            label="ID"
            fullWidth
            size='small'
            margin="normal"
            value={loginId}
            onChange={(event) => setLoginId(event.target.value)}
          />
          {id > 0 ? '' : <TextField
            label="パスワード"
            type="password"
            autoComplete="current-password"
            fullWidth
            size='small'
            margin="normal"
            onChange={(event) => setPassword(event.target.value)}
          />}
          {id > 0 ? '' : <TextField
            label="パスワード(確認)"
            type="password"
            autoComplete="current-password"
            fullWidth
            size='small'
            margin="normal"
            onChange={(event) => setPassword2(event.target.value)}
          />}
          <TextField
            label="名字"
            type="text"
            autoComplete="current-password"
            fullWidth
            size='small'
            margin="normal"
            value={familyName}
            onChange={(event) => setFamilyName(event.target.value)}
          />
          <TextField
            label="名前"
            type="text"
            autoComplete="current-password"
            fullWidth
            size='small'
            margin="normal"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
          <TextField
            label="参考"
            type="text"
            fullWidth
            multiline
            rows={2}
            margin="normal"
            value={memo}
            onChange={(event) => setMemo(event.target.value)}
          />
          <JoinDatePick setJoinDate={setJoinDate} joinDate={joinDate} />
          { id > 0 && <JoinStatusCheckbox visibleFlag={visibleFlag} deleteFlag={deleteFlag} setDeleteFlag={setDeleteFlag} setVisibleFlag={setVisibleFlag} />}
        </div>
      </Box>
      <button className="joinBtn" onClick={id > 0 ? updateUserInfo : getJoinUser }>{id > 0 ?'変更':'登録'}</button>
      <Box sx={{textAlign: 'center', marginTop: '3em'}}>
        {id > 0 ? <h2>パスワード変更</h2> : ''}
        {id > 0 ? <TextField
          label="パスワード"
          type="password"
          autoComplete="current-password"
          fullWidth
          size='small'
          margin="normal"
          onChange={(event) => setPassword(event.target.value)}
        /> : ''}
        {id > 0 ? <TextField
          label="パスワード(確認)"
          type="password"
          autoComplete="current-password"
          fullWidth
          size='small'
          margin="normal"
          onChange={(event) => setPassword2(event.target.value)}
        /> : ''}
        {id > 0 ? 
          <button className="joinBtn" onClick={change_pass}>変更</button>
        : ''}
      </Box>
      {renderLoadingImage()}
    </>
  );
}

export default JoinInputForm;
// import React, { useState, useEffect } from 'react';
// import FullCalendar from '@fullcalendar/react'
// import multiMonthPlugin from '@fullcalendar/multimonth'
// import jaLocale from '@fullcalendar/core/locales/ja';
// import AxiosConfig from '../../AxiosConfig';


// function setClassNames(event) {

//   let holiday = '';
//   event.forEach(element => {
//     console.log(element);
//     if(element.status === 4 || element.status === '4') {
//       holiday = 'holiday_etc';
//       return holiday;
//     }
//   });
// }


// const HolidayCalendar = (props) => {

//     const [holidays, setHolidays] = useState(props.holidays ? props.holidays : '');

//     const events = holidays ? holidays.map(holiday => ({
//       title: holiday.memo, 
//       date: holiday.holiday_date,
//       status: holiday.status,   
//     })) : [
//       // { title: '祝日', date: '2023-04-01' },
//     ];  

//     console.log(events);

//     return (
//         <FullCalendar
//             plugins={[ multiMonthPlugin ]}
//             initialView="multiMonthYear"
//             locale={jaLocale}
//             multiMonthMaxColumns={2}
//             events={events}
//             eventClassNames={events ? setClassNames(events) : ''}
//         />
//     );
// };

// export default HolidayCalendar;

import React, { useState, useEffect, useCallback } from 'react';
import FullCalendar, {EventClickArg} from '@fullcalendar/react'
import multiMonthPlugin from '@fullcalendar/multimonth'
import jaLocale from '@fullcalendar/core/locales/ja';
import AxiosConfig from '../../AxiosConfig';

const HolidayCalendar = (props) => {

    const [holidays, setHolidays] = useState(props.holidays ? props.holidays : '');

    const events = holidays ? holidays.map(holiday => ({
      title: holiday.memo, 
      date: holiday.holiday_date,
      status: holiday.status,   
    })) : [];

    const handleEventClick = useCallback((clickInfo: EventClickArg) => {
        alert(`${clickInfo.event.title}`)
    }, []);

    return (
        <FullCalendar
            plugins={[ multiMonthPlugin ]}
            initialView="multiMonthYear"
            locale={jaLocale}
            multiMonthMaxColumns={2}
            events={events}
            eventClassNames={(eventInfo) => { 
              return eventInfo.event.extendedProps.status === 4 ? ['holiday_etc'] 
              : eventInfo.event.extendedProps.status === 2 ? ['holiday_notice']
              : eventInfo.event.extendedProps.status === 3 ? ['holiday_expire']
              : ['']; 
            }}
            eventClick={handleEventClick}
        />
    );
};

export default HolidayCalendar;
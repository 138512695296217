import React, {useEffect, useState} from 'react';
import styles from './mypage.module.scss';
import SideMenuTab from '../../components/SideMenuTab';
import MyPageForm from '../../components/mypage/MyPageForm';
import AxiosConfig from '../../AxiosConfig';
import MyPagePassForm from '../../components/mypage/MyPagePassForm';

const Mypage = () => {


  const [users, setUsers] = useState([]);

  useEffect(() => {

      if(localStorage.users) {
        setUsers(JSON.parse(localStorage.users));
      }

      // const data = {
      //   'id': users.id,
      // };
      // AxiosConfig.get('/api/getUserinfo', { params: data })
      //   .then(response => {
      //     setUsers(response.data.res.users);
      //   }).catch(error => {
      //     console.log(error); 
      //   });
      
  }, []);


  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.sub_header}>
            <div className={styles.header_button}>
              {/* <button className={styles.edit}>戻る</button> */}
              <nav className={styles.tabs}>
                <button className="left">マイページ</button>
              </nav> 
              <SideMenuTab usertype={users.admin_type} />
            </div>   
          </div>
        </header>
        
        <main className={styles.main_content}>
          <MyPageForm users={users} setUsers={setUsers} />
          <MyPagePassForm users={users} />
        </main>
      </div>
    </>
  );
};

export default Mypage;
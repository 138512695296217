import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu';
import { BrowserView, MobileView } from 'react-device-detect'; // モバイル、デスクトップ区分コード



const SideMenuTab = (props) => {

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const navigate = useNavigate();

  const goMypage = () => {
    
    navigate('/mypage');
  }
  
  const goJoinpage = () => {
    
    navigate('/join');

  }
  const goDashboard = () => {
    
    navigate('/dashboard');

  }
  const goExpense = () => {
    
    navigate('/expense');

  }
  const goDocument = () => {
    
    navigate('/document');

  }

  const goAdminpage = () => {
    
    navigate('/adminboard');

  }
  
  const goExpenseboard = () => {
    
    navigate('/expenseboard');

  }
  
  const goHolidaypage = () => {
    
    navigate('/holidayboard');

  }
  
  const list = (anchor) => (
    
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 200 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem key="goMypage" disablePadding>
          <ListItemButton>
            <ListItemText primary="マイページ" onClick={goMypage} />
          </ListItemButton>
        </ListItem>
        <ListItem key="goDashboard" disablePadding>
          <ListItemButton>
            <ListItemText primary="ダッシュボード" onClick={goDashboard} />
          </ListItemButton>
        </ListItem>
        <ListItem key="goExpense" disablePadding>
          <ListItemButton>
            <ListItemText primary="経費申請" onClick={goExpense} />
          </ListItemButton>
        </ListItem>
        <ListItem key="goDocument" disablePadding>
          <ListItemButton>
            <ListItemText primary={props.usertype === 1 ? '書類管理':'書類ダウンロード'} onClick={goDocument} />
          </ListItemButton>
        </ListItem>
        <BrowserView>
          {props.usertype === 1 && (
          <ListItem key="goAdminpage" disablePadding>
            <ListItemButton>
              <ListItemText primary="勤怠管理(管理者)" onClick={goAdminpage} />
            </ListItemButton>
          </ListItem>
          )}
          {props.usertype === 1 && (
          <ListItem key="goAdminpage2" disablePadding>
            <ListItemButton>
              <ListItemText primary="経費申請(管理者)" onClick={goExpenseboard} />
            </ListItemButton>
          </ListItem>
          )}
          {props.usertype === 1 && (
          <ListItem key="goHolidaypage" disablePadding>
            <ListItemButton>
              <ListItemText primary="祝日管理(管理者)" onClick={goHolidaypage} />
            </ListItemButton>
          </ListItem>
          )}
          {props.usertype === 1 && (
          <ListItem key="goJoinpage" disablePadding>
            <ListItemButton>
              <ListItemText primary="社員登録(管理者)" onClick={goJoinpage} />
            </ListItemButton>
          </ListItem>
          )}
        </BrowserView>
      </List>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuIcon onClick={toggleDrawer(anchor, true)} />
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}


export default SideMenuTab;
import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../pages/dashboard/dashboard.module.scss';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import "./css/css.scss";
import AxiosConfig from '../../AxiosConfig';
import TimePicker from './Timepicker';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// function formatTime(timeString) {
//     if (!timeString) return "";

//     const timeParts = timeString.split(':');
//     const hours = parseInt(timeParts[0], 10);
//     const minutes = parseInt(timeParts[1], 10);

//     const currentDate = new Date();
//     const dateWithTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hours, minutes);

//     return dateWithTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
// }

function formatTime(time) {
    if (time) {
        return time.slice(0, 5);
    }
    return '';
}


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, minWidth: '200px' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function calculateNightWorkHours(startTime, endTime, restTime) {
    
    if(!startTime || !endTime || !restTime) {
        return false;
    } 

    let [startHours, startMinutes, startSeconds] = startTime.split(":").map(String);
    let [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
    let [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
    
    let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
    let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
    let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

    let nightTimeStart1 = start;
    nightTimeStart1.setHours(startHours, startMinutes, startSeconds);
    
    let nightTimeEnd2 = end;
    nightTimeEnd2.setHours(endHours, endMinutes, endSeconds);

    let nightTimeRest3 = rest;
    nightTimeRest3.setHours(restHours, restMinutes, restSeconds);

    if(Number(endHours) === 0) {
        endHours = 24;
    }
    if(Number(endHours) === 1) {
        endHours = 25;
    }
    if(Number(endHours) === 2) {
        endHours = 26;
    }
    if(Number(endHours) === 3) {
        endHours = 27;
    }
    if(Number(endHours) === 4) {
        endHours = 28;
    }
    if(Number(endHours) === 5) {
        endHours = 29;
    }
    if(Number(endHours) === 6) {
        endHours = 30;
    }

    let nightWorkTime = Number(endHours) - Number(startHours) - Number(restHours);
    if(nightWorkTime < 0) {
        nightWorkTime = 24 + Number(endHours) - Number(startHours) - Number(restHours);
    }
    let nightWorkTimeMin; 
    // nightWorkTimeMin = Number(endMinutes) - Number(startMinutes);
    if(Number(endHours) === 22 || Number(endHours) === 23 ) {
        nightWorkTimeMin = Number(endMinutes) - Number(restMinutes);
    } else if(Number(endHours) >= 24 || Number(endHours) <= 29 ) {
        nightWorkTimeMin = Number(endMinutes);
    } else {
        nightWorkTimeMin = Number(endMinutes) - Number(startMinutes) - Number(restMinutes);
    }
    // console.log('4', Number(endHours));
    // console.log('nightWorkTimeMin', nightWorkTimeMin);
    // console.log('nightWorkTime', nightWorkTime);

    if(!((startHours >= 22 || startHours <= 6 ) || ( endHours >= 22 || endHours <= 6))) { 
        return '00:00';
    } else {
        if((startHours >= 22 && endHours <= 23) || (startHours >= 22 && endHours <= 6) || ((startHours < 6 && ( endHours > startHours && (endHours === startHours ? endMinutes > startMinutes:''))) && endHours <= 6)) {
            nightWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
            if(nightWorkTime > 8) {
                if(Number(startHours) >= 22) {
                    nightWorkTime = 24 - Number(startHours);
                    if(6 - Number(endHours) > 0) {
                        nightWorkTime = nightWorkTime + (6 - Number(endHours));
                    } else {
                        nightWorkTime = nightWorkTime + 6;
                        nightWorkTimeMin = 0 - Number(startMinutes);
                    }
                } else if(Number(startHours) >= 0 && Number(startHours) <= 6 && Number(endHours) >= 7) {
                    nightWorkTime = 6 - Number(startHours);
                    if(nightWorkTime < 0) {
                        nightWorkTime = 0;
                    }
                } else {
                    nightWorkTime = 8;
                    nightWorkTimeMin = 0;
                }

            } else {
              if(nightWorkTime <= 0 && nightWorkTimeMin <= 0) {
                nightWorkTime = 0;
                nightWorkTimeMin = 0;
              }
            }

            if(nightWorkTimeMin < 0) {
                nightWorkTimeMin += 60;
                nightWorkTimeMin %= 60;
                if(nightWorkTimeMin < 0) {
                    nightWorkTimeMin += 60;
                    nightWorkTimeMin %= 60;
                }
                nightWorkTime -= 1;
            }
            
        } else {
            if(nightWorkTimeMin < 0) {
                nightWorkTimeMin += 60;
                nightWorkTimeMin %= 60;
                nightWorkTime -= 1;
                if(nightWorkTimeMin < 0) {
                    nightWorkTimeMin += 60;
                    nightWorkTimeMin %= 60;
                }
            }
            if(nightWorkTime > 8) {

                nightWorkTime = Number(endHours)-22;
                if(nightWorkTime >= 8) {
                    nightWorkTimeMin = 0;
                }

                if(nightWorkTime < 0) {
                    // nightWorkTime = 24+Number(endHours)-22;
                    nightWorkTime = Number(endHours) - Number(startHours) - Number(restHours);
                    // if(Number(endHours) > 22 && nightWorkTimeMin < )
                    if (nightWorkTime < 0 && nightWorkTimeMin < 0) {
                        nightWorkTime = 0;
                        nightWorkTimeMin = 0;
                    } 
                }   

            } else {
                if( nightWorkTime < 0 ) {
                    
                    // nightWorkTime = 8;
                    nightWorkTime = Number(endHours) - Number(startHours) - Number(restHours);
                    if(nightWorkTime <= 0 && nightWorkTimeMin >= 1) {
                        nightWorkTimeMin = 0;
                    }
                    else if(nightWorkTimeMin > 0) {
                        nightWorkTimeMin += 60;
                        nightWorkTimeMin %= 60;
                        nightWorkTime -= 1;
                    }

                } else {

                    nightWorkTime = Number(endHours)-22;
                    if(nightWorkTime < 0) {
                        if(Number(startHours) <= 6 && Number(endHours) > Number(startHours)) {
                            nightWorkTime = Number(endHours) - Number(startHours) - Number(restHours);
                        } else {
                            nightWorkTime = 24+Number(endHours)-22;
                        }
                    }
                    if(nightWorkTimeMin > 0) {
                        nightWorkTimeMin += 60;
                        nightWorkTimeMin %= 60;
                        
                    }
                    if(Number(endMinutes) < Number(startMinutes)) {
                        nightWorkTime -= 1;
                    } else if (Number(endMinutes) === Number(startMinutes) && Number(restMinutes) > 0) {
                        nightWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
                        nightWorkTimeMin += 60;
                        nightWorkTimeMin %= 60;
                        nightWorkTime -= 1;
                        // console.log('123', nightWorkTimeMin);
                    } else if(Number(endMinutes) < (Number(startMinutes) + Number(restMinutes))) {
                        nightWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
                        nightWorkTimeMin += 60;
                        nightWorkTimeMin %= 60;
                        nightWorkTime -= 1;
                        // console.log('123', nightWorkTimeMin);
                    }
                }
            }
        }
    }

    
    const hours = Math.floor(nightWorkTime).toString().padStart(2, '0');
    const minutes = Math.floor(nightWorkTimeMin).toString().padStart(2, '0');
    // const seconds = Math.floor(((nightWorkTime % 1) * 60) % 1 * 60).toString().padStart(2, '0');
    // return `${hours}:00`;
    return `${hours}:${minutes}`;

}



function calculateWorkHours(startTime, endTime, restTime) {
    
    if(!startTime || !endTime || !restTime) {
        return false;
    } 

    const [startHours, startMinutes, startSeconds] = startTime.split(":").map(String);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
    const [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
    
    let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
    let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
    let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

    const workTimeStart1 = start;
    workTimeStart1.setHours(startHours, startMinutes, startSeconds);
    
    const workTimeEnd2 = end;
    workTimeEnd2.setHours(endHours, endMinutes, endSeconds);

    const workTimeRest3 = rest;
    workTimeRest3.setHours(restHours, restMinutes, restSeconds);
 
    let totalWorkTime;
    let totalWorkTimeMin;
    if(endHours > startHours) {
        totalWorkTime = endHours - startHours - restHours;
    } else {
        totalWorkTime = 24 + ((endHours - startHours) - restHours);
        if(endHours === startHours) {
            totalWorkTime = 0;
            totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
        }
    }

    if(Number(startMinutes) === Number(endMinutes)) {
        totalWorkTimeMin = 0 - Number(restMinutes);
    } else {
        totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
    }

    if(totalWorkTimeMin > 60) { 
        totalWorkTime -= 1;
        totalWorkTimeMin %= 60;
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin = 60 - totalWorkTimeMin;
        }
        // console.log('1-1', totalWorkTime);
        // console.log('1-1', totalWorkTimeMin);
    } else if (totalWorkTimeMin === 60) { // over 60min
        totalWorkTimeMin %= 60;
        // totalWorkTime -= 1;
    } else {
        // console.log('2-1', totalWorkTime);
        // console.log('2-1', totalWorkTimeMin);
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin %= 60;
        } else {
            if(totalWorkTimeMin < 0) {
                totalWorkTimeMin = 60 + totalWorkTimeMin;
                totalWorkTime -= 1;
                if(totalWorkTimeMin < 0) {
                    totalWorkTimeMin += 60;
                    totalWorkTimeMin %= 60;
                    totalWorkTime -= 1;
                }
                if(totalWorkTime < 0) {
                    totalWorkTime = 0;
                    totalWorkTimeMin = 0;
                }
            } else {
                totalWorkTimeMin %= 60;
            }
            // console.log('3-1', totalWorkTime);
            // console.log('3-1', totalWorkTimeMin);
        }
        totalWorkTimeMin %= 60;
    }
    // console.log('4-1', totalWorkTime);
    // console.log('4-1', totalWorkTimeMin);
    
    if(totalWorkTime <= 0 && totalWorkTimeMin <= 0) { //時間確認必要
        return '00:00'; 
    } 
    
    const hours = Math.floor(totalWorkTime).toString().padStart(2, '0');
    const minutes = Math.floor(totalWorkTimeMin).toString().padStart(2, '0');

    if(hours >= 12) {
        calculateNightWorkHours(startTime, endTime, restTime);
    }

    return `${hours}:${minutes}`;

}

function calculateUnderWorkHours(startTime, endTime, restTime) {
    

    if(!startTime || !endTime || !restTime) {
        return false;
    } 

    const [startHours, startMinutes, startSeconds] = startTime.split(":").map(String);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
    const [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
    
    let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
    let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
    let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

    const workTimeStart1 = start;
    workTimeStart1.setHours(startHours, startMinutes, startSeconds);
    
    const workTimeEnd2 = end;
    workTimeEnd2.setHours(endHours, endMinutes, endSeconds);

    const workTimeRest3 = rest;
    workTimeRest3.setHours(restHours, restMinutes, restSeconds);
 
    let totalWorkTime;
    let totalWorkTimeMin;
    if(endHours > startHours) {
        totalWorkTime = endHours - startHours - restHours;
    } else {
        totalWorkTime = 24 + ((endHours - startHours) - restHours);
        if(endHours === startHours) {
            totalWorkTime = 0;
            totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
        }
    }

    if(Number(startMinutes) === Number(endMinutes)) {
        totalWorkTimeMin = 0 - Number(restMinutes);
    } else {
        totalWorkTimeMin = (Number(endMinutes) - Number(startMinutes)) - Number(restMinutes);
    }

    if(totalWorkTimeMin > 60) { 
        totalWorkTime -= 1;
        totalWorkTimeMin %= 60;
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin = 60 - totalWorkTimeMin;
        }
    } else if (totalWorkTimeMin === 60) { // over 60min
        totalWorkTimeMin %= 60;
    } else {
        if(totalWorkTimeMin > 0) {
            totalWorkTimeMin %= 60;
        } else {
            if(totalWorkTimeMin < 0) {
                totalWorkTimeMin = 60 + totalWorkTimeMin;
                totalWorkTime -= 1;
                if(totalWorkTimeMin < 0) {
                    totalWorkTimeMin += 60;
                    totalWorkTimeMin %= 60;
                    totalWorkTime -= 1;
                }
                if(totalWorkTime < 0) {
                    totalWorkTime = 0;
                    totalWorkTimeMin = 0;
                }
            } else {
                totalWorkTimeMin %= 60;
            }
        }
        totalWorkTimeMin %= 60;
    }
    
    if(totalWorkTime <= 0 && totalWorkTimeMin <= 0) { //時間確認必要
        return '08:00'; 
    } 
    
    const hours = totalWorkTime;
    const minutes = totalWorkTimeMin;
    
    // let underHours = Number(8) - Number(hours);
    // let underMinutes = Number(minutes);

    // if(underHours < 0) {
    //     alert('123')
    //     underHours = Number(0);
    //     underMinutes = Number(0);
    // } else if(underHours === 0 && minutes > 0) {
    //     underHours = Number(0);
    //     alert('456')
    // } else {
    //     underMinutes = Number(60) - Number(minutes);
    // }


    let underHours;
    let underMinutes;

    if(Number(hours) >= 8) {

        underHours = 0;
        underMinutes = 0;
    
    } else {
        
        if(Number(hours) >= 0) {
            underHours = 8 - Number(hours);
            if(Number(minutes) > 0) {
                underMinutes = 60 - Number(minutes);
                if(underHours > 0) {
                    underHours = underHours - 1;
                    // console.log(underHours);
                } 
            } else {
                underMinutes = Number(minutes);
            }
        } else {
            underHours = 0;
            if(minutes > 0) {
                underMinutes = 60 - Number(minutes);
            } else {
                underMinutes = Number(minutes);
            }
        }
    
    }
    
    underHours = Math.floor(underHours).toString().padStart(2, '0');
    underMinutes = Math.floor(underMinutes).toString().padStart(2, '0');
    
    return `${underHours}:${underMinutes}`; 
    
}


function calculateOverWorkHours(endTime_tmp, endTime, startTime, startTime_tmp, restTime) {
    
    //　時間外労働は4時間を超えないこと

    if(!endTime_tmp || !endTime || !startTime || !startTime_tmp || !restTime) {
        return false;
    } 

    const nightWorkTime = calculateNightWorkHours(startTime, endTime, restTime);
    const totalWorkTime = calculateWorkHours(startTime, endTime, restTime);
    let hoursOfNightWork = nightWorkTime.split(':')[0];
    let hoursOftotalWork = totalWorkTime.split(':')[0];
    let minutesOfNightWork = nightWorkTime.split(':')[1];
    let minutesOftotalWork = totalWorkTime.split(':')[1];

    const [stHours, stMinutes, stSeconds] = startTime.split(":").map(String);
    const [startHours, startMinutes, startSeconds] = endTime_tmp.split(":").map(String);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(String);
    const [restHours, restMinutes, restSeconds] = restTime.split(":").map(String);
    
    let start = new Date(`2000-01-01T${startHours+':'+startMinutes+':'+startSeconds}Z`);
    let end = new Date(`2000-01-01T${endHours+':'+endMinutes+':'+endSeconds}Z`);
    let rest = new Date(`2000-01-01T${restHours+':'+restMinutes+':'+restSeconds}Z`);

    const overTimeStart1 = start; //endTime_tmpの時間
    overTimeStart1.setHours(startHours, startMinutes, startSeconds);
    
    const overTimeEnd2 = end; //endTimeの時間
    overTimeEnd2.setHours(endHours, endMinutes, endSeconds);

    const overTimeRest3 = rest; //restTimeの時間
    overTimeRest3.setHours(restHours, restMinutes, restSeconds);

    let overMinOfHour;
    let overMin;
    
    if(Math.floor((Number(minutesOfNightWork) + Number(minutesOftotalWork)) / 60) >= 1) {
        overMinOfHour = Math.floor((Number(minutesOfNightWork) + Number(minutesOftotalWork)) / 60);

        if(Number(minutesOfNightWork) === Number(minutesOftotalWork)) {
            overMin = 0;
        } else {
            overMin = (Number(minutesOfNightWork) - Number(minutesOftotalWork)) % 60;
        }

    } else {
        overMinOfHour = 0;

        if(Number(minutesOfNightWork) === Number(minutesOftotalWork)) {
            overMin = 0;
        } else {
            overMin = (Number(minutesOfNightWork) - Number(minutesOftotalWork)) % 60;
        }
    }
        
    let overWorkTime;
    let overWorkTimeMin;


    if((Number(hoursOftotalWork) - Number(hoursOfNightWork) - overMinOfHour) < 8) { //8時間超えない場合

        overWorkTime = 0;
        overWorkTimeMin = 0;

    } else if((Number(hoursOftotalWork) - Number(hoursOfNightWork) - overMinOfHour) === 8) {//8時間の場合(分を計算するため)
        
        overWorkTime = 0;
        overWorkTimeMin = Number(minutesOftotalWork) - Number(minutesOfNightWork);

        if(Number(minutesOftotalWork) <  Number(minutesOfNightWork)) {

            overWorkTimeMin += 60;
            overWorkTimeMin %= 60;
            
            if(overWorkTime >= 1) {
                overWorkTime -= 1;
            }

            if(Number(hoursOftotalWork) - Number(hoursOfNightWork) <= 8) {
                if(Number(minutesOftotalWork) - Number(minutesOfNightWork) <= 0) {
                    overWorkTime = 0;
                    overWorkTimeMin = 0;
                }
            } 
            
        }
 
        

        // console.log('1', overWorkTime);
        // console.log('1-1', overWorkTimeMin);
        
        
    } else { //8時間超える場合
        
        // alert('2')
        overWorkTime = (Number(hoursOftotalWork) - Number(hoursOfNightWork) - overMinOfHour) - 8;
        overWorkTimeMin = overMin;
        

        // console.log('2', overWorkTime);
        // console.log('2-1', overWorkTimeMin);

        if(overMinOfHour > 0) {
            overWorkTime += overMinOfHour;
        } 

        if(overWorkTimeMin > 60) {  // 60分を超えた時
            overWorkTimeMin %= 60;
            if(overWorkTimeMin > 0) {
                overWorkTimeMin = 60 - overWorkTimeMin;
            }
            // console.log('3', overWorkTime);
            // console.log('3-1', overWorkTimeMin);
        } else if (overWorkTimeMin === 60) { // 60分の時
            overWorkTimeMin %= 60;
            // console.log('4', overWorkTime);
            // console.log('4-1', overWorkTimeMin);
        } else {
            if(overWorkTimeMin > 0) {
                overWorkTimeMin = 60 - overWorkTimeMin;
                overWorkTime -= 1;
                // console.log('5', overWorkTime);
                // console.log('5-1', overWorkTimeMin);
            } else {
                if(overWorkTimeMin < 0) {
                    overWorkTimeMin = 60 - overWorkTimeMin;
                    overWorkTimeMin %= 60;
                    // overWorkTime -= 1;
                    // console.log('6', overWorkTime);
                    // console.log('6-1', overWorkTimeMin);
                } else {
                    overWorkTimeMin %= 60;
                    // console.log('7', overWorkTime);
                    // console.log('7-1', overWorkTimeMin);
                }
            }
        }

    }
    // console.log('8', overWorkTime);
    // console.log('8-1', overWorkTimeMin);
 
    
    const hours = Math.floor(overWorkTime).toString().padStart(2, '0');
    const minutes = Math.floor(overWorkTimeMin).toString().padStart(2, '0');
    // const seconds = Math.floor(((overWorkTime % 1) * 60) % 1 * 60).toString().padStart(2, '0');
    // return `${hours}:00`;
    return `${hours}:${minutes}`;
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const WorkFormModal = (props) => {

    const handleClickClose = (param) => {
        if(param === 'work') {
            props.onAlertModalClose('work');
        } else {
            props.onAlertModalClose();
        }
        setTimeout(() => {
            if (props.works && props.works.hasOwnProperty('time_in')) {
                setStartTime(props.works.time_in);
                setEndTime(props.works.time_out);
                setRestTime(props.works.time_rest);
                setNightWorkTime(props.works.night_work_time);
                setOverWorkTime(props.works.over_work_time);
                setHolidayWorkTime(props.works.holiday_work_time);
                setUsers(props.users);
                setTemplate_in(props.users.template_in);
                setTemplate_out(props.users.template_out);
                setTemplate_rest(props.users.template_rest);
                setIsHoliday(props.works.is_holiday_work);
                setWorkTime(props.works.work_time);
                setMemo(props.works.memo);
                setUnderWorkTime(props.works.under_work_time);
            }
            setWorks(props.works);
            setDate(props.date);
        }, 300);
    };

    const [year, month, day] = props.date.split("-");
    const formattedDate = `${month}/${day}`; 
    const [users, setUsers] = useState([]);

    const [template_in, setTemplate_in] = useState();
    const [template_out, setTemplate_out] = useState();
    const [template_rest, setTemplate_rest] = useState();
    const [change, setChange] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const renderLoadingImage = () => {
        return isLoading ? (
        <div className="loading-container">
            {/* <img src="https://596576bca7dcb637.main.jp/tool/image/loading_bar.gif" alt="Loading" /> */}
            <img src="https://596576bca7dcb637.main.jp/tool/image/loading-bar.gif" alt="loading" style={{ width: '50px' }} />
        </div>
        ) : null;
    };
    useEffect(() => {
        setWorks(props.works);
        setDate(props.date);
        setHolidayType(props.holidayType);
        if(props.works) {
            setStartTime(props.works.time_in);
            setEndTime(props.works.time_out);
            setRestTime(props.works.time_rest);
            setNightWorkTime(props.works.night_work_time);
            setOverWorkTime(props.works.over_work_time);
            setHolidayWorkTime(props.works.holiday_work_time);
            setUsers(props.users);
            setTemplate_in(props.users.template_in);
            setTemplate_out(props.users.template_out);
            setTemplate_rest(props.users.template_rest);
            setIsHoliday(props.works.is_holiday_work);
            setWorkTime(props.works.work_time);
            setMemo(props.works.memo);
            setUnderWorkTime(props.works.under_work_time);
        }
    }, [props.works, props.isAttendanceVisible, props.date, props.users]);

    useEffect(() => {

        if(props.isChange === true) {

            const data = {
                'user_id': props.userid ? props.userid : props.users.id,
                'date': props.date,
            };
            
            // console.log(data);
            AxiosConfig.get('/api/get_work_time', { params: data })
            .then((res) => {
                // console.log(res);
                setStartTime(res.data.res.data.time_in.slice(0, 5));
                setEndTime(res.data.res.data.time_out.slice(0, 5));
                setRestTime(res.data.res.data.time_rest.slice(0, 5));
                if(props.works) {
                    setNightWorkTime(props.works.night_work_time);
                    setOverWorkTime(props.works.over_work_time);
                    setHolidayWorkTime(props.works.holiday_work_time);
                    setUsers(props.users);
                    setTemplate_in(props.users.template_in);
                    setTemplate_out(props.users.template_out);
                    setTemplate_rest(props.users.template_rest);
                    setIsHoliday(props.works.is_holiday_work);
                    setWorkTime(props.works.work_time);
                    setMemo(props.works.memo);
                    setUnderWorkTime(props.works.under_work_time);
                }
            }).catch(error => {
                alert('エラー');
                console.log(error);
            }).finally(() => {
                setChange(true);
                props.setIsChange(false);
            });
        }

    }, [props.isChange, props.isAttendanceVisible]);



    const [works, setWorks] = useState([]);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [restTime, setRestTime] = useState();
    const [underWorkTime, setUnderWorkTime] = useState('00:00');
    const [overWorkTime, setOverWorkTime] = useState('00:00');
    const [nightWorkTime, setNightWorkTime] = useState('00:00');
    const [holidayWorkTime, setHolidayWorkTime] = useState('00:00');
    const [workTime, setWorkTime] = useState('00:00');
    const [isHoliday, setIsHoliday] = useState(false);
    const [date, setDate] = useState();
    const [memo, setMemo] = useState();
    const [holidayType, setHolidayType] = useState(props.holidayType ? props.holidayType : '');

    useEffect(() => {
        calculateTime();
        setChange(false);
    }, [startTime, endTime, restTime, change]);

    const updateWork = async() => {


        let owner;
        if(localStorage.users) {
          owner = JSON.parse(localStorage.users);
        }


        const data = {
            'user_id' : !props.userid ? users.id : props.userid,
            'date': date,
            'time_in' : startTime,
            'time_out' : endTime,
            'time_rest' : restTime,
            'over_work_time' : holidayType === 'overTime' ? holidayWorkTime : overWorkTime,
            'night_work_time' : nightWorkTime,
            'holiday_work_time' : holidayType === 'holidayTime' ? holidayWorkTime : '00:00',
            'under_work_time': underWorkTime,
            'work_time': workTime,
            'memo' : memo,
            'update_user_name' : owner.family_name !== users.family_name ? owner.family_name : users.family_name,
        }

        // console.log(data);

        // if (window.confirm("保存しますか？")) {
        // }

        try {
            // console.log(data);
    
            const response = await AxiosConfig.post('/api/update_worktime', data);
    
            if (response.status === 200) {
                // alert('保存しました!');
                handleClickClose('work');
            } else {
                alert('保存失敗しました!');
            }
        } catch (error) {
            console.log(error);
            alert('保存失敗しました!');
        }
    } 


    const calculateTime = () => {
        setNightWorkTime(calculateNightWorkHours(startTime, endTime, restTime));
        setOverWorkTime(calculateOverWorkHours(template_out, endTime, startTime, template_in, restTime));
        setWorkTime(calculateWorkHours(startTime, endTime, restTime));
        setUnderWorkTime(calculateUnderWorkHours(startTime, endTime, restTime));
        if(isHoliday === true) {
            setHolidayWorkTime(calculateWorkHours(startTime, endTime, restTime));
        }
    }

    return (
    <div>
      <BootstrapDialog
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickClose}>
            {formattedDate}({props.weekday})勤務詳細入力
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <div className={styles.main_div}>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        出勤時刻   
                    </div>
                    <div className={styles.line_time_div}>
                        <TimePicker
                            type="start"
                            value={startTime}
                            calculateTime={calculateTime}
                            setStartTime={setStartTime}
                        />
                        {/* <Input
                            type='time'
                            value={startTime}
                            onChange={(e) => {
                                const selectedTime = e.target.value;
                                const hours = selectedTime.slice(0, 2);
                                let minutes = selectedTime.slice(3);

                                // If the minutes are not '00' or '30', set them to '00'
                                if (minutes !== "00" && minutes !== "15" && minutes !== "30" && minutes !== "45") {
                                    minutes = "00";
                                }

                                const newTime = hours + ':' + minutes + ':00';
                                
                                setStartTime(newTime);
                                calculateTime();
                            }}
                            inputProps={{
                                sx: {
                                    textAlign: "right",
                                    "&::placeholder": {
                                        textAlign: "right",
                                    },
                                },
                            }}
                        /> */}
                    </div>
                </div>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        退勤時刻   
                    </div>
                    <div className={styles.line_time_div}>
                        <TimePicker
                            type="end"
                            value={endTime}
                            calculateTime={calculateTime}
                            setEndTime={setEndTime}
                        />
                        {/* <Input
                            type='time'
                            value={endTime}
                            onChange={(e) => {
                                const selectedTime = e.target.value;
                                const hours = selectedTime.slice(0, 2);
                                let minutes = selectedTime.slice(3);

                                if (minutes !== "00" && minutes !== "15" && minutes !== "30" && minutes !== "45") {
                                    minutes = "00";
                                }

                                const newTime = hours + ':' + minutes + ':00';
                                
                                setEndTime(newTime);
                                calculateTime();
                            }}
                            inputProps={{
                                sx: {
                                    textAlign: "right",
                                    "&::placeholder": {
                                        textAlign: "right",
                                    },
                                },
                            }}
                        /> */}
                    </div>
                </div>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        休憩時間   
                    </div>
                    <div className={styles.line_time_div}>
                        <TimePicker
                            type="rest"
                            value={restTime}
                            calculateTime={calculateTime}
                            setRestTime={setRestTime}
                        />
                        {/* <Input
                            type='time'
                            value={restTime}
                            step="900"
                            onChange={(e) => {
                                const selectedTime = e.target.value;
                                let hours = selectedTime.slice(0, 2);
                                let minutes = selectedTime.slice(3);
                                if (minutes !== "00" && minutes !== "15" && minutes !== "30" && minutes !== "45") {
                                    minutes = "00";
                                }
                                if(Number(hours) > 3) { // 4時間以上入力防止
                                    hours = "01";
                                }

                                const newTime = hours + ':' + minutes + ':00';
                                
                                setRestTime(newTime);
                                calculateTime();
                            }}
                            inputProps={{
                            sx: {
                                textAlign: "right",
                                "&::placeholder": {
                                    textAlign: "right",
                                },
                            },
                        }}
                        /> */}
                    </div>
                </div>
            </div>
            <div className={styles.main_div}>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        時間外労働
                    </div>
                    <div className={styles.line_time_div}>
                        <Input
                            placeholder="00:00"
                            value={formatTime(overWorkTime)}
                            disabled
                            inputProps={{
                                sx: {
                                    textAlign: "right",
                                    "&::placeholder": {
                                    textAlign: "right",
                                    },
                                },
                            }}
                        />                        
                    </div>
                </div>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        深夜労働
                    </div>
                    <div className={styles.line_time_div}>
                        <Input
                            placeholder="00:00"
                            value={formatTime(nightWorkTime)}
                            disabled
                            inputProps={{
                                sx: {
                                    textAlign: "right",
                                    "&::placeholder": {
                                    textAlign: "right",
                                    },
                                },
                            }}
                        />  
                    </div>
                </div>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        所定労働未達時間
                    </div>
                    <div className={styles.line_time_div}>
                        <Input
                            placeholder="00:00"
                            value={formatTime(underWorkTime)}
                            disabled
                            inputProps={{
                                sx: {
                                    textAlign: "right",
                                    "&::placeholder": {
                                    textAlign: "right",
                                    },
                                },
                            }}
                        />  
                    </div>
                </div>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        法定休日労働
                    </div>
                    <div className={styles.line_time_div}>
                        <Input
                            value={ isHoliday === true ? formatTime(holidayWorkTime) : '00:00'}
                            disabled
                            inputProps={{
                                sx: {
                                    textAlign: "right",
                                    "&::placeholder": {
                                    textAlign: "right",
                                    },
                                },
                            }}
                        />  
                    </div>
                </div>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        合計労働時間
                    </div>
                    <div className={styles.line_time_div}>
                        <Input
                            value={formatTime(workTime)}
                            disabled
                            inputProps={{
                                sx: {
                                    textAlign: "right",
                                    "&::placeholder": {
                                    textAlign: "right",
                                    },
                                },
                            }}
                        />  
                    </div>
                </div>
            </div>
            <div className={styles.main_div}>
                <TextField
                    id="outlined-multiline-static"
                    label="備考"
                    value={memo}
                    multiline
                    rows={2}
                    style={{ width : '100%' }}
                    onChange={(e) => setMemo(e.target.value)}
                />
            </div>
            <div className={styles.main_div}>
                <Button variant="outlined" fullWidth={true} onClick={updateWork} >登録</Button>
            </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default React.memo(WorkFormModal);
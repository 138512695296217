import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '0 20px'
  };

  const buttonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    backgroundColor: '#007BFF',
    color: 'white',
    textDecoration: 'none'
  };

  const refreshPage = () => {
    window.location.reload();
  };


  return (
    <div style={style}>
      <h1>ページをアップデートしています。</h1>
      <p>しばらくお待ちください。</p>
      <Link onClick={refreshPage} style={buttonStyle}>再読み込み</Link>
    </div>
  );
};

export default NotFound;

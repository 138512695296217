import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const JoinStatusCheckbox = (props) => {

  // console.log('props', props);
  return (
    <FormGroup>
      <FormControlLabel 
        control={
          <Checkbox 
            checked={props.visibleFlag} 
            onChange={(e) => props.setVisibleFlag(e.target.checked)} 
          />
        } 
        label="アカウントを非表示にする" 
      />
      <FormControlLabel 
        control={
          <Checkbox 
            checked={props.deleteFlag} 
            onChange={(e) => props.setDeleteFlag(e.target.checked)} 
          />
        } 
        label="ログイン不可にする" 
      />
    </FormGroup>
  );

}

export default JoinStatusCheckbox;
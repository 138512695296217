import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import styles from '../../pages/mypage/mypage.module.scss';
import Input from '@mui/material/Input';
import "../mypage/css/css.scss";
import AxiosConfig from '../../AxiosConfig';
import TimePicker from '../dashboard/Timepicker';
import Box from '@mui/material/Box';


// function formatTime(timeString) {
//     if (!timeString) return "";
  
//     const timeParts = timeString.split(':');
//     const hours = parseInt(timeParts[0], 10);
//     const minutes = parseInt(timeParts[1], 10);
  
//     const currentDate = new Date();
//     const dateWithTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hours, minutes);
  
//     return dateWithTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
// }

const MyPageForm = (props) => {
    
    // const handleInputChange = (field, e) => {
    //     props.setUsers({
    //       ...props.users,
    //       [field]: e.target.value,
    //     });
    // };
    const date = new Date();
    const initialYear = date.getFullYear();
    const initialMonth = date.getMonth() + 1;
    const [year, setYear] = useState(initialYear);
    const [month, setMonth] = useState(initialMonth);
    
    const [users, setUsers] = useState([]);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [restTime, setRestTime] = useState('');
    const [status, setStatus] = useState(false);
  
  
    useEffect(() => {


        let user;
        if(localStorage.users) {
            setUsers(JSON.parse(localStorage.users));
            user = JSON.parse(localStorage.users)
        }
    
        const data = {
          'id': user.id,
        };

        AxiosConfig.get('/api/getUserinfo', { params: data })
        .then((res) => {
            console.log(res);
            setUsers(res.data.res.users);
            setStartTime(startTime ? startTime : res.data.res.users.template_in ? res.data.res.users.template_in : '09:00');
            setEndTime(endTime ? endTime : res.data.res.users.template_out ? res.data.res.users.template_out : '18:00');
            setRestTime(restTime ? restTime : res.data.res.users.template_rest ? res.data.res.users.template_rest : '01:00');
        }).catch(error => {
            alert('エラー');
            console.log(error);
        });
      
      setStatus(false);
    }, [setStatus, endTime, restTime, startTime])
  
    const updateWork = async() => {
  
      const data = {
          'user_id' : users.id,
          'template_in' : startTime,
          'template_out' : endTime,
          'template_rest' : restTime,
          'updated_user_name' : users.family_name,
      }
      // console.log(data);
      // if (window.confirm("保存しますか？")) {
      // }
      try {
        //   console.log(data);
  
          const response = await AxiosConfig.post('/api/update_templatetime', data);
  
          if (response.data.res.code === 'success') {
              alert('保存しました!');
              setStatus(true);
          } else {
              alert('保存失敗しました!');
          }
      } catch (error) {
          console.log(error);
          alert('保存失敗しました!');
      }
  
    }  

    const calculateTime = () => {
    }


  return (
    <>
        <Box sx={{ maxWidth: '80%', display: 'flex',flexDirection: 'column', alignSelf: 'center', margin :'3em 0', minHeight: '35vh' }}> 
            <div className='myChangeTime'>
                <h3 style={{textAlign: 'center', fontWeight: '400'}}>打刻テンプレート</h3>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        出勤時刻   
                    </div>
                    <div className={styles.line_time_div}>
                        <TimePicker
                            type="start"
                            value={startTime}
                            calculateTime={calculateTime}
                            setStartTime={setStartTime}
                            page="MyPage"
                        />
                    </div>
                </div>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        退勤時刻   
                    </div>
                    <div className={styles.line_time_div}>
                        <TimePicker
                            type="end"
                            value={endTime}
                            calculateTime={calculateTime}
                            setEndTime={setEndTime}
                            page="MyBoard"
                        />                        
                    </div>
                </div>
                <div className={styles.main_line_div}>
                    <div className={styles.line_title_div}>
                        休憩時間   
                    </div>
                    <div className={styles.line_time_div}>
                        <TimePicker
                            type="rest"
                            value={restTime}
                            calculateTime={calculateTime}
                            setRestTime={setRestTime}
                            page="MyBoard"
                        />
                    </div>
                </div>
                {/* <button className="ChangePassBtn" onClick={updateWork}>登録</button> */}
                <Button variant="outlined" size="medium" onClick={updateWork} sx={{marginTop: '1em'}}>登録</Button>
            </div>
        </Box>
    </>
  );
}

// export default AlertModal;

export default React.memo(MyPageForm);
import React from 'react';
import styles from '../../pages/dashboard/dashboard.module.scss';

const Requireform = (props) => {

  // const dateObj = new Date(props.summary.join_date);
  // let formattedDate = `${dateObj.getMonth() + 1}月${dateObj.getDate()}日`;
  
  const joinDate = new Date(props.summary.join_date);
  const sixMonths = 6;
  const updatedJoinDate = new Date(joinDate.setMonth(joinDate.getMonth() + sixMonths));
  // const formattedDate = `${updatedJoinDate.getMonth() + 1}月 1日`;
  
  const formattedDate = `${updatedJoinDate.getMonth() + 1}月 ${updatedJoinDate.getDate()}日`;
  // console.log(props.summary.join_date);
  
  return (  
    <>
      <section className={`${styles.section} ${styles.section_right}`}>
        <div className={styles.section_row}>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>出勤日数</p>
              <span>{props.summary.work_days ? parseInt(props.summary.work_days, 10) : '0'}日</span>
            </div>
          </div>          
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>労働時間(H)</p>
              <span>{(((props.summary.work_times) && props.summary.work_times.split(':')[0] !== '00') || ((props.summary.work_times) && props.summary.work_times.split(':')[1] !== '00')) ? parseInt(props.summary.work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(props.summary.work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</span>
              {/* <span>{(props.summary.work_times && props.summary.work_times.split(':')[0] !== '00' || props.summary.work_times.split(':')[1] !== '00') ? parseInt(props.summary.work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(props.summary.work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</span> */}
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>所定労働未達時間(H)</p>
              <span>{(((props.summary.under_work_times) && props.summary.under_work_times.split(':')[0] !== '00') || ((props.summary.under_work_times) && props.summary.under_work_times.split(':')[1] !== '00')) ? parseInt(props.summary.under_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(props.summary.under_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</span>
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>時間外労働(H)</p>
              <span>{(((props.summary.over_work_times) && props.summary.over_work_times.split(':')[0] !== '00') || ((props.summary.over_work_times) && props.summary.over_work_times.split(':')[1] !== '00')) ? parseInt(props.summary.over_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(props.summary.over_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</span>
              {/* <span>{(props.summary.over_work_times && props.summary.over_work_times.split(':')[0] !== '00'  || props.summary.over_work_times.split(':')[1] !== '00') ? parseInt(props.summary.over_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(props.summary.over_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</span> */}
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>深夜労働(H)</p>
              <span>{(((props.summary.night_work_times) && props.summary.night_work_times.split(':')[0] !== '00') || ((props.summary.night_work_times) && props.summary.night_work_times.split(':')[1] !== '00')) ? parseInt(props.summary.night_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(props.summary.night_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</span>
              {/* <span>{(props.summary.night_work_times && props.summary.night_work_times.split(':')[0] !== '00'  || props.summary.night_work_times.split(':')[1] !== '00') ? parseInt(props.summary.night_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(props.summary.night_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</span> */}
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>法定休日労働(H)</p>
              <span>{(((props.summary.holiday_work_times) && props.summary.holiday_work_times.split(':')[0] !== '00') || ((props.summary.holiday_work_times) && props.summary.holiday_work_times.split(':')[1] !== '00')) ? parseInt(props.summary.holiday_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(props.summary.holiday_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</span>
              {/* <span>{(props.summary.holiday_work_time && props.summary.holiday_work_time.split(':')[0] !== '00'  || props.summary.holiday_work_time.split(':')[1] !== '00')  ? parseInt(props.summary.holiday_work_time.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(props.summary.holiday_work_time.split(':')[1], 10).toString().padStart(2, '0') : '0'}</span> */}
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>遅刻日数</p>
              <span>{props.summary.late_days ? parseInt(props.summary.late_days, 10) : '0'}日</span>
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>早退日数</p>
              <span>{props.summary.early_days ? parseInt(props.summary.early_days, 10) : '0'}日</span>
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>欠勤日数</p>
              <span>{props.summary.absence_days ? parseInt(props.summary.absence_days, 10) : '0'}日</span>
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>休出日数</p>
              <span>{props.summary.absence_days ? parseInt(props.summary.absence_days, 10) : '0'}日</span>
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>特休日数</p>
              <span>{props.summary.special_holiday ? parseInt(props.summary.special_holiday, 10) : '0'}日</span>
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>有給日数</p>
              <span>{props.summary.paid_days ? props.summary.paid_days : '0'}日</span>
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>有給残日数</p>
              <span>{props.summary.paid_value ? props.summary.paid_value : '0'}日</span>
            </div>
          </div>
        </div>
        <div className={styles.section_row}>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>有給付与日</p>
              <span>{formattedDate}</span>
            </div>
          </div>
          <div className={styles.section_item}>
            <div className={styles.section_content}>
              <p>前回有給付与日数</p>
              <span>{props.summary.paid_holiday ? parseInt(props.summary.paid_holiday, 10) : '0'}日</span>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default Requireform;
import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import HolidayCalendar from './HolidayCalendar';
import AxiosConfig from '../../AxiosConfig';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [holidays, setHolidays] = useState([]);

    const handleClickOpen = () => {
        props.setOpen(true);
    };

    const handleClose = () => {
        props.setOpen(false);
    };


    useEffect(() => {
        
        const data = {
            // 'user_id': owner.id,
            // 'year': year,
            // 'month': month,
        };

        AxiosConfig.get('/api/get_holiday_api', { params: data })
        .then((res) => {
            // console.log(res);
            setHolidays(res.data.res.data);
        }).catch(error => {
            alert('エラー');
            console.log(error);
        }).finally(() => {
            // setIsLoading(false);
            // setIsRefresh(false);
        });

    }, []);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#3C486B' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              カレンダー
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              閉じる
            </Button>
          </Toolbar>
        </AppBar>
        <List>
            <HolidayCalendar holidays={holidays} />
        </List>
      </Dialog>
    </React.Fragment>
  );
}
import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import styles from '../../pages/mypage/mypage.module.scss';
import Input from '@mui/material/Input';
import "../mypage/css/css.scss";
import AxiosConfig from '../../AxiosConfig';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


// function formatTime(timeString) {
//     if (!timeString) return "";
  
//     const timeParts = timeString.split(':');
//     const hours = parseInt(timeParts[0], 10);
//     const minutes = parseInt(timeParts[1], 10);
  
//     const currentDate = new Date();
//     const dateWithTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hours, minutes);
  
//     return dateWithTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
// }

const MyPagePassForm = (props) => {
    
    // const handleInputChange = (field, e) => {
    //     props.setUsers({
    //       ...props.users,
    //       [field]: e.target.value,
    //     });
    // };
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');  


    useEffect(() => {


      let user;
      if(localStorage.users) {
          user = JSON.parse(localStorage.users)
      }

      setId(user.id);
    
  }, [])




    const change_pass = async() => {


        const data = {
          'id' : id,
          'login_password' : password,
        }
        
        // console.log(data);
        if(!password || !password2) {
          alert('パスワードを入力してください。');
          return false;
        } else if(password !== password2) {
          alert('パスワードと確認パスワードは一致していません。');
          return false;
        } 
        // else if(/\s/.test(password)) {
        //   alert('スペースは入力できません。');
        //   return false;
        // } else if(password.length < 8) {
        //   alert('パスワードは8文字以上入力してください。');
        //   return false;
        // } else if(!/\d/.test(password)) {
        //   alert('パスワードは数字を含めてください。');
        // } else if(!/[a-z]/.test(password)) {
        //   alert('パスワードは英小文字を含めてください。');
        // } else if(!/[A-Z]/.test(password)) {
        //   alert('パスワードは英大文字を含めてください。');
        // } 
        else {
          // console.log(data);
          // setIsLoading(true);
          AxiosConfig.get('/sanctum/csrf-cookie').then(response => {
            AxiosConfig.post('/api/change_pass', data)
            .then((res) => {
              // console.log(res);
              if(res.data.res.code  === 'success') {
                alert(res.data.res.msg);
              } else {
                alert(res.data.res.msg);
              }
            }).catch(error => {
                alert('変更失敗');
                console.log(error);
            }).finally(() => {
              setPassword('');
              setPassword2('');
              // window.location.reload();
              // setIsLoading(false);
            });
          });
        }
    }


    return (
        <> 
            <Box sx={{ maxWidth: '80%', display: 'flex',flexDirection: 'column', alignSelf: 'center', margin :'1em 0', minHeight: '40vh'}}>
              <h3 style={{textAlign: 'center', fontWeight: '400'}}>パスワード変更</h3>
              <div>
                  <TextField
                    label="新しいパスワード"
                    type="password"
                    autoComplete="current-password"
                    fullWidth
                    size='small'
                    margin="normal"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <TextField
                    label="新しいパスワード(確認)"
                    type="password"
                    autoComplete="current-password"
                    fullWidth
                    size='small'
                    margin="normal"
                    value={password2}
                    onChange={(event) => setPassword2(event.target.value)}
                  />
              </div>
              {/* <button className="ChangePassBtn" onClick={change_pass}>パスワード変更</button> */}
              <Button variant="outlined" size="medium" onClick={change_pass} sx={{marginTop: '1em'}}>パスワード変更</Button>
            </Box>
        </>
    );
}


export default React.memo(MyPagePassForm);
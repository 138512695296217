import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import AxiosConfig from '../../AxiosConfig';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const InputFileUpload = (props) => {

    const [text, setText] = useState(props.fileName !== '' ? props.fileName : '');

    
    const handleFileUpload = (event) => {
      uploadFile(event.target.files[0]);
    };
    
    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append('file', file);

      AxiosConfig.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
      .then((response) => {
        console.log(response);
        props.setFileName(response.data.file);
        setText(response.data.file);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.error);
      })
      .finally(() => {
      });
    }

    return (
      <> 
        <div style={{display: 'flex', flexDirection:'column', maxWidth: '100%', width: '100%'}}>
          <Button component="label" variant="contained" startIcon={<DriveFolderUploadRoundedIcon />}>
            アップロード
            <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
          </Button>
          {
            text !== ''  ? <p style={{clipPath: 'view-box', margin: '0'}}>{text}</p> : ''
          }
        </div>
      </>
    );
}

export default InputFileUpload;
import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../pages/holidayboard/holidayboard.module.scss';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import AxiosConfig from '../../AxiosConfig';
import SelectBox from './SelectBox';
import SelectDate from './SelectDate';
import dayjs from 'dayjs';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2, minWidth: '200px' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  } 

const HolidayFormModal = (props) => {

    const handleClickClose = (param) => {
        if(param === 'new') {
            setTimeout(() => {
                setHolidayDate(dayjs().format('YYYY-MM-DD'));
                props.setIsLoading(true);
                props.setIsRefresh(true);
            }, 300);
        }
        props.onAlertModalClose();
    };

    // const [isLoading, setIsLoading] = useState(false);
    const [holidayDate, setHolidayDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [holidayDateNew, setHolidayDateNew] = useState(dayjs().format('YYYY-MM-DD'));
    const [memo, setMemo] = useState('');
    const [status, setStatus] = useState(props.data? props.data.status : 1);



    useEffect(() => {


        if(props.data) {
            setHolidayDate(props.data.holiday_date);
            setMemo(props.data.memo);
            setStatus(props.data.status);
        }

        
    }, [props.isRefresh, props.data])
    
    const createHoliday = async() => {

        if(memo.length < 2) {
            alert('内容を入力してください。');
            return false;
        } else {

            let owner;
            if(localStorage.users) {
              owner = JSON.parse(localStorage.users);
            }
            
            if (props.data && props.data.holiday_date !== '') {
    
                const data = {
                    'holiday_date' : props.data.holiday_date,
                    'holiday_date_new' : holidayDate,
                    'memo' : memo,
                    'family_name': owner.family_name,
                    'status': status,
                }
                // console.log(data);
        
                try {
            
                    const response = await AxiosConfig.post('/api/update_holiday', data);
            
                    if (response.data.res.code === 'success') {
                        // console.log(response);
                        // alert('保存しました!');
                        handleClickClose('update');
                        props.setIsRefresh(true);
                    } else {
                        alert('保存失敗しました!');
                    }
                } catch (error) {
    
                    console.log(error);
                    alert('保存失敗しました!');
    
                }
    
            } else {
    
                const data = {
                    'holiday_date' : holidayDate,
                    'memo' : memo,
                    'family_name': owner.family_name,
                    'status': status,
                }
        
        
                try {
            
                    const response = await AxiosConfig.post('/api/create_holiday', data);
            
                    if (response.data.res.code === 'success') {
                        // console.log(response);
                        // alert('保存しました!');
                        handleClickClose('new');
                        props.setIsRefresh(true);
                    } else {
                        alert('保存失敗しました!');
                    }
                } catch (error) {
                    console.log(error);
                    alert('保存失敗しました!');
                }
            }

        }

    } 


    return (
    <>
        <BootstrapDialog
            onClose={handleClickClose}
            aria-labelledby="customized-dialog-title"
            open={props.isOpen}
            >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickClose}>
                祝日入力
            </BootstrapDialogTitle>
            <DialogContent dividers >
                <div className={styles.main_div}>
                    <div className={styles.main_line_div}>
                        <div className={styles.line_title_div}>
                            日付
                        </div>
                        <div className={styles.line_time_div}>
                            <SelectDate setHolidayDate={setHolidayDate} holidayDate={holidayDate} />
                        </div>
                    </div>
                    <div className={styles.main_line_div}>
                        <div className={styles.line_title_div}>
                            分類
                        </div>
                        <div className={styles.line_time_div}>
                            <SelectBox setStatus={setStatus} status={status} />
                        </div>
                    </div>
                </div>
                <div className={styles.main_div}>
                    <TextField
                        id="outlined-multiline-static"
                        label="内容"
                        value={memo}
                        multiline
                        rows={3}
                        style={{ width : '100%' }}
                        onChange={(e) => setMemo(e.target.value)}
                    />
                </div>
                <div className={styles.main_div}>
                    <Button variant="outlined" fullWidth={true} onClick={createHoliday}>登録</Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    </>
  );
}

export default React.memo(HolidayFormModal);
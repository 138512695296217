/* eslint-disable no-restricted-globals */
import './login.scss';
import { useState } from 'react';
import ApplicationLogo from '../../components/login/ApplicationLogo';
import AxiosConfig from '../../AxiosConfig';
import LoginInputForm from '../../components/login/LoginInputForm';
import axios from 'axios';

function Login() {

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const handleIdChange = (Id) => {
    setId(Id);
  }
  const handlePassChange = (Pass) => {
    setPassword(Pass);
  }

  const [isLoading, setIsLoading] = useState(false);

  const renderLoadingImage = () => {
    return isLoading ? (
      <div className="loading-container">
        {/* <img src="https://596576bca7dcb637.main.jp/tool/image/loading_bar.gif" alt="Loading" /> */}
        <img src="https://596576bca7dcb637.main.jp/tool/image/loading-bar.gif" alt="loading" style={{ width: '50px' }} />
      </div>
    ) : null;
  };

  const getLoginUser = async() => {
      const data = {
        'login_id' : id,
        'login_password' : password,
      }
      // console.log(data);
      if(!id || !password) {
        alert('IDまたはパスワードを入力してください。');
      } else {
        setIsLoading(true);
        AxiosConfig.get('/sanctum/csrf-cookie').then(response => {
          AxiosConfig.post('/api/loginproc', data)
          .then((res) => {
            if(res.data.res.code  === 'success') {
              // alert(res.data.res.msg);
              // alert(res.data.res.users.id);
                const isMobile = /Mobi|Android|iPhone/.test(navigator.userAgent);
                if(res.data.res.users.admin_type === 1 && !isMobile) {//管理者は管理ページに進む
                  localStorage.setItem('users', JSON.stringify(res.data.res.users));
                  localStorage.setItem('loginTime', new Date().getTime());
                  location.href = '/adminboard';
                } else{

                  if(Number(res.data.res.users.id) > 0) {
                  // sessionStorage.clear(); //sessionStorage初期化
                  const data = {
                    'year': new Date().getFullYear(),
                    'month': new Date().getMonth() + 1,
                    'userid': res.data.res.users.id,
                  };
                  
                  AxiosConfig.get('/api/getDates', { params: data })
                  .then(response => {
                      // console.log(response);
                      const newData = {
                        dates: response.data.res.dates,
                        holidays: response.data.res.holidays,
                        summary: response.data.res.summary,
                        is_submit: response.data.res.is_submit,
                        month: response.data.res.month,
                        year: response.data.res.year
                      };
                      sessionStorage.setItem('myData', JSON.stringify(newData));
                      sessionStorage.setItem('userid', res.data.res.users.id);
                    })
                    .catch(error => console.log(error))
                    .finally(() => {
                      localStorage.setItem('users', JSON.stringify(res.data.res.users));
                      localStorage.setItem('loginTime', new Date().getTime());
                      const session = sessionStorage.getItem('myData');
                      if(session) {
                        // console.log(JSON.parse(session));
                        location.href = '/dashboard'; 
                      }
                    });
                  }

                }
                
            } else {
              alert('ログイン失敗しました。');
            }
          }).catch(error => {
              alert('ログイン失敗');
              console.log(error);
          }).finally(() => {
            setIsLoading(false);
          });
        });
      }
  }
  
  return (
    <div className="login-form-container">
      <div className="login-form">
          <div className="text-center">
              <ApplicationLogo className="w-50" />
          </div>
          <h1>アールツー</h1>
          <LoginInputForm id={id} password={password} handleIdChange={handleIdChange} handlePassChange={handlePassChange} />
          <button onClick={getLoginUser}>ログイン</button>
          {renderLoadingImage()}
      </div>
    </div>
  );
}

export default Login;
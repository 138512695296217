import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectBox = (props) => {

  const [status, setStatus] = React.useState('');
  const handleChange = (e) => {
    const value = e.target.value;
    setStatus(value);
    props.setStatus(value);
    if(value === 1){
      props.setStartDate(null); 
    }
};
  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  return (
    <Box sx={{ minWidth: 120 }}  >
      <FormControl fullWidth size="small">
        <Select
          defaultValue={1}
          value={status}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={age}
          onChange={handleChange}
          // onChange={(e) => props.setStatus(e.target.value) }
        >
          <MenuItem value={1}>なし</MenuItem>
          {/* <MenuItem value={2}>中断</MenuItem> */}
          <MenuItem value={3}>あり</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectBox;
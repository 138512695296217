import React, { useState, useRef, useEffect, useContext } from 'react';
import styles from './dashboard.module.scss';
import Requireform from '../../components/dashboard/Requireform';
import CustomFlatpickr from '../../components/dashboard/CustomFlatpickr';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { common } from '@mui/material/colors';
import MyListMenu from '../../components/dashboard/MyListMenu';
import SideMenuTab from '../../components/SideMenuTab';
import SubmitConfirm from '../../components/dashboard/SubmitConfirm';
import AxiosConfig from '../../AxiosConfig';
import { LoadingContext } from '../../LoadingContext';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors'
import FullScreenDialog from '../../components/dashboard/FullScreenDialog';

const Dashboard = () => {

  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const date = new Date();
  const initialYear = date.getFullYear();
  const initialMonth = (date.getMonth() + 1).toString().padStart(2, '0');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const paramsyear = queryParams.get('year');
  const paramsmonth = queryParams.get('month');
  const paramsuserid = queryParams.get('id');
  const [dates, setDates] = useState([]);
  const [holiday, setHoliday] = useState([]);
  const [otherDay, setOtherDay] = useState([]);
  const [users, setUsers] = useState([]);
  const [year, setYear] = useState(paramsyear ? paramsyear : initialYear);
  const [month, setMonth] = useState(paramsmonth ? paramsmonth : initialMonth);
  const [userid, setUserid] = useState(paramsuserid ? paramsuserid : '');
  const [username, setUsername] = useState([]);
  const [isAttendanceVisible, setIsAttendanceVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("left");
  const [isHoliday, setIsHoliday] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isStatus, setIsStatus] = useState(false)
  const [timeIn, setTimeIn] = useState('00:00');
  const [timeOut, setTimeOut] = useState('00:00');
  const [timeRest, setTimeRest] = useState('00:00');
  const [isCount, setIsCount] = useState(Number(sessionStorage.getItem('isCount')) > 0? Number(sessionStorage.getItem('isCount')): 0);
  // const [isCount, setIsCount] = useState(Number(sessionStorage.setItem('isCount')) > 0? Number(sessionStorage.setItem('isCount')): 0);
  const [currentUserid, setCurrentUserid] = useState();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [start, setStart] = useState();
  const [summary, setSummary] = useState([]);
  const handleDateChange = (selectedDate) => {
    setStart(selectedDate);
    setIsAttendanceVisible(!isAttendanceVisible);
  };
  const getYear = (year) => {
    setYear(year);
  }
  const getMonth = (month) => {
    setMonth(month);
  }

  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const handleClickOpen = () => {
    setCalendarOpen(true);
  };

  const handleClose = () => {
    setCalendarOpen(false);
  };

  const flatpickrRef = useRef();

  const handleClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.focus();
      flatpickrRef.current.click(); 
    }
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  }));

  // console.log(isCount);

  useEffect(() => {

    let owner;
    if(localStorage.users) {
      setUsers(JSON.parse(localStorage.users));
      owner = JSON.parse(localStorage.users);
    }
    const user_id = sessionStorage.getItem('userid');
    const userName = JSON.parse(sessionStorage.getItem('userName'));
    const storedData = JSON.parse(sessionStorage.getItem('myData'));
    // console.log('user_id', users);
    if(!storedData || isCount > 3 || isStatus === true || Number(storedData.month) === Number(month) || Number(storedData.year) === Number(year)) {
      // console.log(JSON.parse(localStorage.users)); 
      if(Number(user_id) > 0 || Number(users.id) > 0 || Number(owner.id) > 0) {
        
        const data = {
          'year': String(year),
          'month': String(month),
          'userid': userid ? userid : user_id ? users.id : owner.id,
        };

        // console.log('data', data);
    
        setUsername(userid ? userName : owner.created_user_name);
        setIsLoading(true);
      
        AxiosConfig.get('/api/getDates', { params: data })
        .then(response => {
          // console.log(response);
          const newData = {
            dates: response.data.res.dates,
            holidays: response.data.res.holidays,
            otherdays: response.data.res.otherdays,
            summary: response.data.res.summary,
            is_submit: response.data.res.is_submit,
            month: response.data.res.month,
            year: response.data.res.year,
            users: response.data.res.users,
          };
          
          if (newData !== storedData) {
              setDates(newData.dates);
              setHoliday(newData.holidays);
              setOtherDay(newData.otherdays);
              setSummary(newData.summary);
              setIsSubmit(newData.is_submit);
              setMonth(newData.month);
              setYear(newData.year);
              setTimeIn(newData.users.template_in);
              setTimeOut(newData.users.template_out);
              setTimeRest(newData.users.template_rest);
              setCurrentUserid(newData.users.id);
    
              sessionStorage.setItem('myData', JSON.stringify(newData));
              sessionStorage.setItem('userName', JSON.stringify(newData.users.created_user_name));
    
          } 
        })
        .catch(error => console.log(error))
        .finally(() => {
          setIsLoading(false);
          if (isCount < 4) {
            setIsCount(0);
            sessionStorage.setItem('isCount', isCount);
          }
        }); 
      }
    } else {

      if(Number(storedData.year) < Number(year)) { //次の　年例外処理

        const data = {
          'year': String(year),
          'month': String(month),
          'userid': userid ? userid : user_id ? users.id : owner.id,
        };

        AxiosConfig.get('/api/getDates', { params: data })
        .then(response => {
          const newData = {
            dates: response.data.res.dates,
            holidays: response.data.res.holidays,
            otherdays: response.data.res.otherdays,
            summary: response.data.res.summary,
            is_submit: response.data.res.is_submit,
            month: response.data.res.month,
            year: response.data.res.year,
            users: response.data.res.users,
          };

          if (newData !== storedData) {
              setDates(newData.dates);
              setHoliday(newData.holidays);
              setOtherDay(newData.otherdays);
              setSummary(newData.summary);
              setIsSubmit(newData.is_submit);
              setMonth(newData.month);
              setYear(newData.year);
              setTimeIn(newData.users.template_in);
              setTimeOut(newData.users.template_out);
              setTimeRest(newData.users.template_rest);
              setCurrentUserid(newData.users.id);
    
              sessionStorage.setItem('myData', JSON.stringify(newData));
    
          } 
        })
        .catch(error => console.log(error))
        .finally(() => {
          setIsLoading(false);
          if (isCount < 4) {
            setIsCount(0);
            sessionStorage.setItem('isCount', isCount);
          }
        }); 

      } else {
        
        const parsedStoredDate = JSON.parse(storedData);
      
        setDates(parsedStoredDate.dates);
        setHoliday(parsedStoredDate.holidays);
        setSummary(parsedStoredDate.summary);
        setOtherDay(parsedStoredDate.otherdays);
        setIsSubmit(parsedStoredDate.is_submit);
        setTimeIn(parsedStoredDate.users.template_in);
        setTimeOut(parsedStoredDate.users.template_out);
        setTimeRest(parsedStoredDate.users.template_rest);
        // setMonth(parsedStoredDate.month);
        // setYear(parsedStoredDate.year);  
  
        setIsLoading(false);
        setIsCount(isCount+1);
        sessionStorage.setItem('isCount', isCount);
      }

    }

  }, [year, month, isAttendanceVisible, isHoliday, activeTab , setIsStatus]);

  console.log('summary', summary);

  return (
    <>
      { isLoading && (
        <div className="loading-container" style={{ zIndex:'999'}}>
          <img src="https://596576bca7dcb637.main.jp/tool/image/loading-bar.gif" alt="loading" style={{ width: '50px' }} />
        </div>
      )}
      <div className={styles.container}>
        {/* { users.admin_type !== 1 ? isSubmit && <div className={styles.overlay}></div> : ''} */}
        <header className={styles.header}>
          <div className={styles.sub_header}>
            <div className={styles.header_datepicker}>
              <CustomFlatpickr onDateChange={handleDateChange} year={year} month={month} getYear={getYear} getMonth={getMonth} ref={flatpickrRef}  />
              <ArrowDownwardRoundedIcon sx={{ color : common.white }} fontSize="large" onClick={handleClick} />
            </div>   
            <div className={styles.header_button}>
              {/* <button className={styles.edit}>戻る</button> */}
              <ColorButton variant="contained" onClick={handleClickOpen} style={{ marginRight: '10px',zIndex: '999' }}>カレンダー</ColorButton>
              <SideMenuTab usertype={users.admin_type} />
            </div>   
          </div>
          <nav className={styles.tabs}>
            <button
              className={activeTab === "left" ? styles.active: styles.disabled}
              onClick={() => handleTabClick("left")}
            >
            登録・参照
            </button>
            <button
              className={activeTab === "right" ? styles.active : styles.disabled}
              onClick={() => handleTabClick("right")}
            >
            概要
            </button>
          </nav>
          <div
            className={styles.user}
          >{username}
          </div>
        </header>
        
        <main className={styles.main_content}>
          {activeTab === "left" && (
            <section className={`${styles.section} ${styles.section_left}`}>
              <ul>
              {
                dates.map((date, index) => {
                  return (
                    <MyListMenu key={`${date.date}-${index}`} date={date.date} weekday={date.weekday} holiday={holiday} otherDay={otherDay} isAttendanceVisible={isAttendanceVisible } setIsAttendanceVisible={setIsAttendanceVisible } works={ date.works } users={users } userid={userid} setIsHoliday={setIsHoliday} setIsLoading={setIsLoading} setIsStatus={setIsStatus} timeIn={timeIn} timeOut={timeOut} timeRest={timeRest} isSubmit={isSubmit} />
                  );
                })
              }
              </ul>
            </section>
          )}
          {activeTab === "right" && (
            <Requireform summary={summary} />
          )}
        </main>
        <footer className={styles.footer}>
          <SubmitConfirm year={year} month={month} users={users} holiday={holiday} isSubmit={isSubmit} setIsAttendanceVisible={setIsAttendanceVisible } isAttendanceVisible={isAttendanceVisible } currentUserid={currentUserid} usertype={users.admin_type} paramsuserid={paramsuserid} />
        </footer>
      </div>
      <FullScreenDialog isOpen={calendarOpen} setOpen={setCalendarOpen} />
    </>
  );
};

export default Dashboard;
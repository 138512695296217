import React, { useState, useEffect , useRef } from 'react';
import styles from './myboard.module.scss'
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import TimePicker from '../../components/dashboard/Timepicker';
import AxiosConfig from '../../AxiosConfig';

const Myboard = () => {

  const [users, setUsers] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [restTime, setRestTime] = useState('');


  useEffect(() => {
    if(localStorage.users) {
      setUsers(JSON.parse(localStorage.users));
    }
  }, [])

  const updateWork = async() => {

    const data = {
        'user_id' : users.id,
        'template_in' : startTime,
        'template_out' : endTime,
        'template_rest' : restTime,
        'updated_user_name' : users.family_name,
    }
    // console.log(data);
    // if (window.confirm("保存しますか？")) {
    // }
    try {
        // console.log(data);

        const response = await AxiosConfig.post('/api/update_templatetime', data);

        if (response.status === 200) {
            alert('保存しました!');
        } else {
            alert('保存失敗しました!');
        }
    } catch (error) {
        console.log(error);
        alert('保存失敗しました!');
    }

  } 

  const calculateTime = () => {
  }
  

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.header_title}>
          <h2>マイページ</h2>
        </div>
      </header>
      <main className={styles.main_content}>
        <div className={styles.main_div}>
          <div className={styles.main_line_div}>
            <div className={styles.line_title_div}>
            出勤時刻   
            </div>
            <div className={styles.line_time_div}>
              <TimePicker
                type="start"
                value={startTime}
                calculateTime={calculateTime}
                setStartTime={setStartTime}
                page="MyBoard"
              />
            </div>
          </div>
          <div className={styles.main_line_div}>
            <div className={styles.line_title_div}>
            退勤時刻   
            </div>
            <div className={styles.line_time_div}>
              <TimePicker
                  type="end"
                  value={endTime}
                  calculateTime={calculateTime}
                  setEndTime={setEndTime}
                  page="MyBoard"
                />                     
            </div>
          </div>
          <div className={styles.main_line_div}>
            <div className={styles.line_title_div}>
            休憩時間   
            </div>
            <div className={styles.line_time_div}>
                <TimePicker
                  type="rest"
                  value={restTime}
                  calculateTime={calculateTime}
                  setRestTime={setRestTime}
                  page="MyBoard"
                />
            </div>
          </div>
          <div className={styles.main_line_div}>
            <Button variant="outlined" fullWidth={true} onClick={updateWork} >登録</Button>
          </div>
        </div>        
      </main>

      <footer className={styles.footer}></footer>
    </div>
  );
};

export default Myboard;


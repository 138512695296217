import React, { useState, useRef, useEffect, useContext } from 'react';
import styles from './document.module.scss';
import CustomFlatpickr from '../../components/dashboard/CustomFlatpickr';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { common } from '@mui/material/colors';
import SideMenuTab from '../../components/SideMenuTab';
import AxiosConfig from '../../AxiosConfig';
import { LoadingContext } from '../../LoadingContext';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import MyDocumentList from '../../components/document/MyDocumentList';
import DocumentFormModal from '../../components/document/DocumentFormModal';

const Document = () => {

  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const [iModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isAttendanceVisible, setIsAttendanceVisible] = useState(false);
  const [document, setDocument] = useState([]);
  const [start, setStart] = useState();
  const [isRefresh, setIsRefresh] = useState(false);


  const handleDateChange = (selectedDate) => {
    setStart(selectedDate);
    setIsAttendanceVisible(!isAttendanceVisible);
  };

  const ModalClickOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = (text) => {
    setIsModalOpen(false);
  };

  const flatpickrRef = useRef();

  const handleClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.focus();
      flatpickrRef.current.click(); 
    }
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  }));



  useEffect(() => {

    setIsLoading(true);

    let owner;
    if(localStorage.users) {
      setUsers(JSON.parse(localStorage.users));
      owner = JSON.parse(localStorage.users);
    } 
    const data = {
    };

    AxiosConfig.get('/api/get_document_api', { params: data })
    .then((res) => {

      console.log(res);
      setDocument(res.data.res.data);

    }).catch(error => {

        alert('エラー');
        console.log(error);

    }).finally(() => {

      setIsLoading(false);
      setIsRefresh(false);

    }); 
      
  }, [isRefresh]);
  



  return (
    <>
      { isLoading && (
        <div className="loading-container" style={{ zIndex:'999'}}>
          {/* <img src="https://596576bca7dcb637.main.jp/tool/image/loading_bar.gif" alt="loading" /> */}
          <img src="https://596576bca7dcb637.main.jp/tool/image/loading-bar.gif" alt="loading" style={{ width: '50px' }} />
        </div>
      )}
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.sub_header}>
            <nav className={styles.tabs}>
            <button
              className={styles.active}
              >
              書類
            </button>
            {/* <button
              className={styles.apply}
              onClick={ModalClickOpen}
            >
              経費申請
            </button> */}
            </nav>
            <div className={styles.header_button}>
              {
                users.admin_type === '1' || users.admin_type === 1 ? 
                <ColorButton variant="contained" onClick={ModalClickOpen} style={{ marginRight: '10px',zIndex: '999' }}>書類登録</ColorButton>
                :
                ''
              }
              {/* <ColorButton variant="contained" onClick={ModalClickOpen} style={{ marginRight: '10px',zIndex: '999' }}>書類登録</ColorButton> */}
              {/* <button className={styles.edit}>戻る</button> */}
              <SideMenuTab usertype={users.admin_type} />
            </div>   
          </div>
        </header>
        
        <main className={styles.main_content}>
          <section className={`${styles.section} ${styles.section_left}`}>
            <ul>
              {
                document.map((key, index) => {
                  return (
                    <MyDocumentList key={`${key}-${index}`} index={index} data={key} users={users} isRefresh={isRefresh} setIsRefresh={setIsRefresh}  />
                  );
                })
              }
            </ul>
          </section>
        </main>
          <DocumentFormModal isOpen={iModalOpen} onAlertModalClose={handleClose} users={users} setIsRefresh={setIsRefresh} />
        <footer className={styles.footer}>
        </footer>
      </div>
    </>
  );
};

export default Document;
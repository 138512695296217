import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useEffect } from 'react';
import { useState } from 'react';
import AxiosConfig from '../../AxiosConfig';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const SubmitConfirm = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [users, setUsers] = useState([]);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}月${day}日`;
  }
  const timeStringToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  const chkErrCode = (dates) => {
    const errors = [];
    dates.forEach(date => {
      let errNum = 0;
      const isHoliday = Object.keys(props.holiday).includes(date.date);
      if(!isHoliday && date.weekday !== "土" && date.weekday !== "日"){
        if (date.works) {
          if (timeStringToSeconds(date.works.work_time) < timeStringToSeconds("08:00:00")) {
            if (!date.works.is_afternoon_rest && !date.works.is_early && !date.works.is_late && !date.works.is_morning_rest){
              if(timeStringToSeconds(date.works.work_time) > 0) errNum = 1;
            }
          }
        }else{
          errNum = 2;
        }
      }
      if(errNum !== 0){
        errors.push({ date: date.date, code: errNum });
      }
    });
    return errors;
  }

  useEffect(() => {
    setUsers(props.users);
    setYear(props.year);
    setMonth(props.month);
  }, [props.users, props.year, props.month]);

  const submitMonthWork = async() => {

    const data = {
      'user_id' : props.currentUserid,
      'date' : year+'-'+month,
      'is_submit': true,
      'create_user_name':users.family_name,
    }
    const checkdata = {
      'year': String(year),
      'month': String(month),
      'user_id' :  props.currentUserid,
    };
    console.log(props.paramsuserid);
    if (props.usertype !== 1 || !props.paramsuserid){
      try{
        const response = await AxiosConfig.get('/api/checkDates', { params: checkdata })

        // console.log(response);
        // 取得したデータを処理
        const messages = chkErrCode(response.data.res.dates).map((date) => {
          if (date.code===1){
            return formatDate(date.date)+"の勤務時間が8時間未満のため、勤怠設定（遅刻など）を入力してください。";
          }
          if (date.code===2){
            return formatDate(date.date)+"の勤怠を入力してください。";
          }
        })
        if(messages.length !== 0){
          const formattedText = messages.filter(message => message !== "").join("\n");
          alert(formattedText);
          
          return;
        }
      } catch (error){
        console.error('API呼び出し中にエラーが発生しました:', error);
        alert('API呼び出し中にエラーが発生しました');
      };
    }
    if(props.isSubmit === true) {

      alert('提出済みです。')
      return false;

    } else {

      if (window.confirm("提出を完了すると編集できなくなります。本当に提出しますか？")) {

        try {
  
            const response = await AxiosConfig.post('/api/submitSummary', data);
            // console.log(response);
            if (response.status === 200) {
              if(response.data.res.code === 'success') {
                // alert(response.data.res.msg);
                props.setIsAttendanceVisible(!props.isAttendanceVisible);
              } else {
                alert(response.data.res.msg);
              }
              // console.log(response);
            } else {
                alert('提出失敗しました。');
            }
        } catch (error) {
            console.log(error);
            alert('提出失敗しました。');
        }
      } 
    } 
  }

  return (
    <>
        <Button variant="contained" color="error" fullWidth={true} size="large" onClick={submitMonthWork} >{props.isSubmit === true ?'勤怠提出済み' : '勤怠提出'}</Button>
    </>
  );
}

export default SubmitConfirm;